<div class="support">
  <mat-menu
    #chat="matMenu"
    [hasBackdrop]="false"
    [overlapTrigger]="false"
    xPosition="before"
    yPosition="below"
    class="support__panel support__chatMenu">
    <div class="support__menuInner" (click)="$event.stopPropagation()">
      <app-support-chat (createDialog)="createDialog()"
                        (onClose)="closeChat()"
                        (sendMessage)="sendMessage(activeChatId, $event)"
                        [activeDialog]="activeChatDialog"
                        [dialogMessages]="messages"
                        [currentUser]="isUserEmail"
      ></app-support-chat>
    </div>
  </mat-menu>

  <div
    #supportTrigger="matMenuTrigger"
    mat-button
    [matMenuTriggerFor]="menu"
    class="support__trigger">
    <img src="/assets/svg/icons/support/comment_white.svg" alt="Support">

    <span>Support</span>

    <div *ngIf="newMessages | async" class="support__unread support__unread--floating">
      {{newMessages | async}}
    </div>
  </div>
  <mat-menu
    #menu="matMenu"
    [hasBackdrop]="false"
    yPosition="above"
    xPosition="before"
    class="support__dialogsMenu support__panel">
    <div
      class="support__menuInner"
      (click)="$event.stopPropagation()">

      <div
        class="support__chatTrigger"
        #chatTrigger="matMenuTrigger"
        [matMenuTriggerFor]="chat">
      </div>

      <div class="support__title">Your dialogues</div>

      <button *ngIf="isAuthenticated | async else auth" class="support__btn" (click)="openChat(null)">
        <img src="/assets/svg/icons/support/plus.svg" alt="+">
        Start a new chat
      </button>

      <ng-template #auth>
        <div style="padding-top: 10px; padding-bottom: 140px" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
          <div fxLayout="column" fxLayoutGap="10px">
            <div class="regInputForms">
              <!--              <mat-form-field>-->
              <mat-label>Email:</mat-label>
              <input matInput [formControl]="formControlEmail" class="support-input-field"
                     [ngClass]="{'support-input-field-red': (formControlEmail.invalid && formControlEmail.touched)}">
              <!--              </mat-form-field>-->
            </div>

            <div>
              <button class="button-exchange"  (click)="authenticate()"
                      [disabled]="formControlEmail.invalid">
                <span class="button-exchange-text">START CHAT</span>
              </button>
            </div>
          </div>

          <!--          -->
          <!--          -->
          <!--          <button mat-stroked-button (click)="authenticate()"-->
          <!--                  [disabled]="formControlEmail.invalid || formControlPassword.invalid">-->
          <!--            Authenticate-->
          <!--          </button>-->
        </div>
      </ng-template>

      <app-support-dialogs
        *ngIf="isAuthenticated|async"
        [dialogs]="dialogs"
        [activeDialog]="activeChatId"
        (onDialogClick)="openChat($event)">
      </app-support-dialogs>

      <div (click)="closeDialogs()" class="support__close">
        <span>Support Chat</span>
        <img src="/assets/svg/icons/support/arrow.svg" alt="▼">
      </div>
    </div>
  </mat-menu>
</div>
