<div class="relative pt-[36px] md:pt-[160px] xl:pt-[288px]">
    <div id="ecosystem" class="relative pt-[80px] md:pt-[80px] xl:pt-[80px] overflow-x-clip">
        <app-container>
            <h1 class="drop-shadow font-manrope font-extrabold mb-6 text-white text-5xl leading-8 xl:text-6xl md:text-center">Part of a powerful ecosystem</h1>
            <p class="text-white text-md mb-9 xl:mb-[80px] xl:text-xl md:text-center">After the tokens are issued they can be offered for sale<br class="hidden md:block"/> to Ubixpay wallet holders and listed on UBIX.Exchange.</p>

            <div class='absolute top-[80px] left-0 flex justify-center w-full h-[1600px] z-[-1] overflow-hidden'>
                <app-gradient-bg class="scale-[.8] hue-rotate-[10deg]"></app-gradient-bg>
            </div>

            <div class="relative">
                <img
                    class="absolute hidden md:block z-[-1] left-[-145px] top-[200px]"
                    src="./assets/images/landing/ecosystem-section/rect1.svg" alt="">
                <img
                    class="absolute hidden md:block z-[-1] right-[-180px] top-[83px]"
                    src="./assets/images/landing/ecosystem-section/rect2.svg" alt="">
                <div class="
                    py-12
                    px-7
                    md:px-12
                    bg-black2
                    border-solid
                    border-[1.5px]
                    border-grey
                    border-opacity-20
                    rounded-2xl
                    shadow-card
                    mb-12 xl:mb-[80px]">

                    <div class="hidden md:grid grid-cols-5 grid-rows-[repeat(3, auto)] items-center">
                        <a href="https://ubixpay.io" target="_blank" class="relative top-[10px]">
                            <svg-icon class="w-full" svgClass="w-full" src="./assets/images/landing/ecosystem-section/ubixpay.svg"></svg-icon>
                        </a>

                        <svg-icon class="m-auto w-full pl-3" svgClass="w-full" src="./assets/images/landing/ecosystem-section/arrow_1_desktop.svg"></svg-icon>


                        <svg-icon class="w-[118px] block m-auto" svgClass="w-full h-auto" src="./assets/images/landing/logo_color.svg"></svg-icon>


                        <svg-icon class="m-auto w-full pr-3" svgClass="w-full" src="./assets/images/landing/ecosystem-section/arrow_2_desktop.svg"></svg-icon>

                        <a href="https://ubix.exchange" target="_blank" class="pt-7">
                            <svg-icon svgClass="w-full" src="./assets/images/landing/ecosystem-section/exchange.svg"></svg-icon>
                        </a>

                        <svg-icon class="col-span-5 flex self-center relative my-6" svgClass="m-auto" src="./assets/images/landing/ecosystem-section/arrow_3_desktop.svg"></svg-icon>

                        <a href="https://ubix.network" target="_blank" class="col-start-3 col-end-4 m-auto">
                            <svg-icon src="./assets/images/landing/ecosystem-section/network.svg"></svg-icon>
                        </a>
                    </div>
                  
                    <div class="flex-col md:hidden">
                        <div class="flex justify-between items-center gap-8">
                            <a href="https://ubixpay.io" target="_blank" class="max-w-[50%]">
                                <svg-icon  svgClass="w-full" src="./assets/images/landing/ecosystem-section/ubixpay.svg"></svg-icon>
                            </a>

                            <a href="https://ubix.exchange" target="_blank" class="max-w-[50%]">
                                <svg-icon svgClass="w-full" src="./assets/images/landing/ecosystem-section/exchange.svg"></svg-icon>
                            </a>
                        </div>
                        <img class="m-auto" src="./assets/images/landing/ecosystem-section/chart_mobile.svg" alt="Chart">
                        <a href="https://ubix.network" target="_blank" >
                            <svg-icon svgClass="m-auto" src="./assets/images/landing/ecosystem-section/network.svg"></svg-icon>
                        </a>
                    </div>
                </div>
            </div>

            <div class="flex flex-col xl:flex-row justify-center">
                <app-home-page class="mb-6 xl:mb-0 xl:mr-8">
                    <app-filling-btn>Issue your token</app-filling-btn>
                </app-home-page>
                <app-filling-btn (click)="openExchange()" class="mb-6 xl:mb-0 xl:mr-8">List your token</app-filling-btn>
                <app-filling-btn (click)="openExchange()">Trade your assets</app-filling-btn>

            </div>
        </app-container>
    </div>
</div>
