<div id="solution">
    <app-container class="
        relative
        max-w-[1100px]
        block
        mx-auto
        pt-[295px]
        xl:pt-[320px]">
        <div class="
            bg-black2
            p-8
            xl:p-12
            border-solid
            border-[1.5px]
            border-grey
            border-opacity-20
            rounded-2xl
            shadow-card
            relative
            grid gap-7 md:gap-0 grid-rows-[165px,_auto] md:grid-rows-none md:grid-cols-[minmax(400px,_auto),_minmax(300px,_405px)]">
            <div class="md:mr-6">
                <h2 class="mb-4 text-white font-manrope font-extrabold text-4xl xl:text-5xl"><span class="text-blue4">Web3</span><br/> monetization solution</h2>
                <p class="text-grey text-md xl:text-xl">Many business processes can be monetized through the issuance of tokens. However, up to now, it has been hampered by technical barriers.<br/><b>With 4Tokens, you can issue tokens in just a few clicks, without writing a single line of code!</b></p>
            </div>
            <div class="order-first md:order-none flex justify-center items-center relative">
                <img
                    class="absolute max-h-[400px] md:max-h-[unset] max-w-full bottom-0 md:bottom-auto md:mb-[60px]"
                    src="./assets/images/landing/woman.png" alt="">
            </div>
        </div>
    </app-container>
</div>
