import { Component, OnInit } from '@angular/core';
import { UseCasesService } from '../../services/useCases.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {Router} from '@angular/router'

@Component({
  selector: 'app-use-case',
  templateUrl: './use-case.component.html',
  styleUrls: ['./use-case.component.scss']
})
export class UseCaseComponent implements OnInit {
  useCasesData: any[] = []
  useCaseId: string | null = null

  constructor(private _service: UseCasesService,
              private _route: ActivatedRoute,
              private _router: Router) { 
  }

  ngOnInit(): void {
    this.useCasesData = this._service.getUseCases()

    this._route.paramMap.subscribe((params: ParamMap) =>  {
        this.useCaseId = params.get('id')
    })

    if (!this.useCasesData.find((item) => item.id === this.useCaseId)) {
      this._router.navigate(['/'])
    }
    console.log(this.useCaseId);
    
  }

  getUseCase(id: string) {
    return this.useCasesData.find((item) => item.id === id)
  }

  getFilteredUseCases() {
    return this.useCasesData.filter((item) => item.id !== this.useCaseId)
  }
}
