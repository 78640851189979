<!-- <div class="error-dialog modal">
  <h2>Error</h2>
  <ng-container *ngIf="errorMsgSubj | async as errorMsg">
    <p>{{errorMsg}}</p>
  </ng-container>
  <div class="buttons">
    <button class="button stroke" type="button" (click)="closeClick()">Close</button>
  </div>
</div> -->
<p (click)="closeClick()"><b>Error! </b><span *ngIf="errorMsgSubj | async as errorMsg">{{errorMsg}}</span></p>
