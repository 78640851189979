<div class="support__dialogsList support--scrollable">
  <div
    *ngFor="let chat of dialogs"
    class="support__dialog"
    [ngClass]="{'support__dialog--active': chat.Id === activeDialog}"
    (click)="dialogClick(chat.Id)">
    <img src="/assets/svg/icons/support/mail.svg" alt="">
    <div class="support__dialogText">
      <div>{{ellipseText(chat.Message.Text)}}</div>
      <div class="support__dialogDate">{{chat.Message.CreationDate | date:'dd.MM.YYYY HH:mm:ss'}}</div>
    </div>
    <div *ngIf="chat.Unread > 0" class="support__unread">{{chat.Unread}}</div>
  </div>

</div>
