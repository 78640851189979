import {Component, Inject, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';

@Component({
  selector: 'app-balance-error',
  templateUrl: './balance-error.component.html'
})
export class BalanceErrorComponent implements OnInit {

  errorMsgSubj = new BehaviorSubject<string | null>(null);

  constructor(public snackBarRef: MatSnackBarRef<BalanceErrorComponent>,
              @Inject(MAT_SNACK_BAR_DATA) public data: any) {
    this.errorMsgSubj.next(data.errorMsg);
  }

  closeClick(): void {
    this.snackBarRef.dismiss();
  }

  ngOnInit(): void {
  }
}
