<div class="message-dialog">
  <button class="close" (click)="closeClick()">
<!--    <img src="../../../../assets/images/icon_close.svg">-->
    <mat-icon>clear</mat-icon>
  </button>
  <h2 class="h2-info">{{data.message}}</h2>


  <form [formGroup]="messageForm">

    <p>&nbsp;</p>
    <mat-form-field>
      <mat-label>Enter your email</mat-label>
      <input id="email" formControlName="email" matInput placeholder="email@example.com" required>
      <mat-error class="error-message" *ngIf="messageForm.get('email')?.touched && messageForm.get('email')?.invalid">{{getEmailErrorMessage()}}</mat-error>
    </mat-form-field>

    <p>&nbsp;</p>
    <mat-form-field>
      <mat-label>Enter your telegram (optional)</mat-label>
      <input id="telegram" formControlName="telegram" type="text" matInput>
    </mat-form-field>

    <p>&nbsp;</p>
    <mat-form-field class="example-full-width">
      <mat-label>Leave a message</mat-label>
      <textarea id="message" formControlName="message" matInput required></textarea>
      <mat-error class="error-message" *ngIf="messageForm.get('message')?.touched && messageForm.get('message')?.invalid">{{getMessageErrorMessage()}}</mat-error>
    </mat-form-field>
  </form>

  <form #form="ngForm">
    <div class="row mt-3">
      <div class="col-sm-12 mb-2">
        <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel" [(ngModel)]="token" required [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"></re-captcha>
      </div>
    </div>
  </form>

  <div mat-dialog-actions>
    <button class="secondaryButton secLeftButton" (click)="closeClick()">Cancel</button>
    <button class="secondaryButton" (click)="Submit(form)" [disabled]="messageForm.invalid || form.invalid!">Send</button>
  </div>

</div>
