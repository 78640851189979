import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { twMerge } from 'tailwind-merge';

@Component({
  standalone: true,
  selector: 'app-btn-outline',
  templateUrl: './btn-outline.component.html',
  imports: [CommonModule],
  styleUrls: ['./btn-outline.component.scss']
})
export class BtnOutlineComponent {
  defaultClasses: string = 'block md:w-[254px] w-full max-w-full'

  @Input() disabled?: boolean | null = false
  @Input() type?: string = 'button'

  @Output() public onClick: EventEmitter<void> = new EventEmitter()

  constructor(private hostElement: ElementRef) {
    hostElement.nativeElement.classList = twMerge(this.defaultClasses, hostElement.nativeElement.classList)
  }
}
