import {Component, OnInit, Pipe, PipeTransform} from '@angular/core';
import {
  AdminExchangeTokenResult,
  AdminTokenStatus,
  MessageType,
  SocialNetworkClient
} from "../../../home-page/components/SocialNetworkClient";
import {BehaviorSubject, catchError, from, Observable, of, timer} from "rxjs";
import {map, mergeMap as _observableMergeMap} from 'rxjs/operators';
import {MatTabChangeEvent} from "@angular/material/tabs";
import {MatDialog} from "@angular/material/dialog";
import {MessageBoxComponent} from "../../components/message-box/message-box.component";
import {environment} from "../../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {TokenInfoBoxComponent} from "../../components/token-info-box/token-info-box.component";
import {LoaderService} from "../../services/loader.service";
import {ActivatedRoute} from "@angular/router";


@Pipe({
  name: 'protectedImage',
})
export class ProtectedImagePipe implements PipeTransform {

  constructor(private http: HttpClient) { }

  async transform(src: string): Promise<string> {
    const headers = new HttpHeaders({'ApiKey': environment.apiKey});
    try {
      const imageBlob = await this.http.get(src, {headers, responseType: 'blob'}).toPromise();
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(imageBlob!);
      });
    } catch {
      return 'assets/images/error_token_logo.png';
    }
  }

}

@Component({
  selector: 'app-tokens',
  templateUrl: './tokens.component.html',
  styleUrls: ['./tokens.component.scss']
})
export class TokensComponent implements OnInit {

  tokenId: string | null | undefined = null

  // loading = new BehaviorSubject<boolean>(false);
  msgSuccess = new BehaviorSubject<boolean>(false);
  msgFailure = new BehaviorSubject<boolean>(false);

  isShowFakeTokens = false;
  id: string;

  dataTokens: AdminExchangeTokenResult[] = [];

  filterValue = '';
  resultsLength = 0;
  currentPage = 1;
  pageSize = '5';

  currentTab = 0;


  constructor(protected socialNetworkClient: SocialNetworkClient,
              public dialog: MatDialog,
              private loaderService: LoaderService,
              private _route: ActivatedRoute) {
    this.loaderService.$showLoader.next(false);
    this.id = this.create_UUID();
  }

  create_UUID(): string {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  ngOnInit() {
    this.updateData();

    this.tokenId = this._route.snapshot.queryParamMap.get('name');
    console.log(this.tokenId);

    if (this.tokenId !== null && this.tokenId !== undefined) {
      this.socialNetworkClient.adminGetExchangeToken(this.tokenId, this.id).subscribe(result => {
        if (result !== null && result !== undefined) {
          this.showToken(result);
        }
      });
    }
  }

  showFake() {
    this.isShowFakeTokens = !this.isShowFakeTokens;
  }

  protected readonly AdminTokenStatus = AdminTokenStatus;

  clickTab(num: number) {
    if (num >= 0 && num <= 2) {
      this.pageSize = "5";
      this.currentPage = 1;
      this.currentTab = num;
    }
    this.updateData();
  }



  showToken(token: AdminExchangeTokenResult) {
    if (token === undefined || token === null) {
      return;
    }
    const dialogRef = this.dialog.open(TokenInfoBoxComponent, {
      panelClass: ['dialog-token-page'],
      width: '800px',
      maxWidth: '100vw',
      data: {'token': token},
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data !== undefined && data !== null) {
        this.showMessageBox(data.currency, data.type, data.message);
      }
    });
  }

  showMessageBox(symbol: string, type: MessageType, message: string): void {

    const dialogRef = this.dialog.open(MessageBoxComponent, {
      panelClass: ['dialog-token-page', 'dialog-token-page--message'],
      width: '800px',
      maxWidth: '100vw',
      data: {'message': message},
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data !== undefined && data !== null) {

        this.loaderService.$showLoader.next(true);
        this.socialNetworkClient.sendMessage(symbol, type, data.email, data.telegram, data.message, this.id).subscribe((isCreated) => {

          this.loaderService.$showLoader.next(false);
          this.msgSuccess.next(true);
          timer(5000).subscribe(() => {
            this.msgSuccess.next(false);
          })

        }, () => {
          this.loaderService.$showLoader.next(false);
          console.log("Error when send message!")
          this.msgFailure.next(true);
          timer(5000).subscribe(() => {
            this.msgFailure.next(false);
          })
        });

      }
    });
  }


  updateData() {
    this.loaderService.$showLoader.next(true);
    const selectedTab = (this.currentTab === 0) ? [AdminTokenStatus.Trust] : ((this.currentTab === 1) ? [AdminTokenStatus.Normal] : [AdminTokenStatus.Fake]);
    this.socialNetworkClient.adminGetExchangeTokens(this.id, selectedTab, 'currency', 'asc', this.currentPage-1, Number(this.pageSize), this.filterValue).subscribe(result => {
      this.resultsLength = result.totalCount ?? 0;
      this.dataTokens = (result.items !== undefined) ? result.items : [];
      this.loaderService.$showLoader.next(false);
    },() => {
      this.loaderService.$showLoader.next(false);
      this.resultsLength = 0;
      this.dataTokens = [];
      console.log("Error when receive tokens from server!")
      this.msgFailure.next(true);
      timer(2000).subscribe(() => {
        this.msgFailure.next(false);
      })
    });
  }

  navFirstPage() {
    if (this.resultsLength > 0) {
      if (this.currentPage > 1) {
        this.currentPage = 1;
        this.updateData();
      }
    }
  }

  navPreviousPage() {
    if (this.resultsLength > 0) {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
        this.updateData();
      }
    }
  }

  navNextPage() {
    if (this.resultsLength > 0) {
      if (this.currentPage > 0) {
        if (this.currentPage < Math.ceil(this.resultsLength/Number(this.pageSize))) {
          this.currentPage += 1;
          this.updateData();
        }
      }
    }
  }

  navLastPage() {
    if (this.resultsLength > 0) {
      if (this.currentPage > 0) {
        if (this.currentPage < Math.ceil(this.resultsLength/Number(this.pageSize))) {
          this.currentPage = Math.ceil(this.resultsLength/Number(this.pageSize))
          this.updateData();
        }
      }
    }
  }

  pageSizeChanged() {
    this.currentPage = 1;
    this.updateData();
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value.trim().toUpperCase();
    this.currentPage = 1;
    this.updateData();
  }

  resetFilter() {
    this.filterValue = '';
    this.currentPage = 1;
    this.updateData();
  }



  protected readonly Number = Number;
  protected readonly Math = Math;

}
