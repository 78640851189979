<button 
    (click)="!disabled && onClick && onClick.emit()"
    [type]="type"
    [disabled]="disabled" 
    class="
        w-full 
        rounded-lg 
        text-white text-md leading-6 font-manrope font-extrabold 
        p-[10px] 
        flex justify-center items-center 
        border-solid border-1 border-white
        hover:text-blue4 hover:border-blue4
        active:text-blue3 active:border-blue3">
    <ng-content></ng-content>
</button>