import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainLayoutComponent } from './shared/components/main-layout/main-layout.component';
import {HttpClientModule} from "@angular/common/http";
import {API_SOCIAL_NETWORK_URL, SocialNetworkClient} from "./home-page/components/SocialNetworkClient";
import {environment} from "../environments/environment";
import { LandingModule } from './landing/landing.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {IdentityClient} from "./shared/api/IdentityClient";
import {SupportChatApiClient} from "./shared/api/supportChatApiClient";
import {AppHeadersServiceProvider} from "./shared/api/appHeaders.service.provider";

@NgModule({
    declarations: [
      AppComponent,
      MainLayoutComponent

    ],
    providers: [
      {
        provide: API_SOCIAL_NETWORK_URL,
        useValue: environment.api.socialNetwork
      },
        SocialNetworkClient,
        IdentityClient,
        SupportChatApiClient,
        AppHeadersServiceProvider,
    ],
    bootstrap: [AppComponent],
    imports: [
      AppRoutingModule,
      BrowserAnimationsModule,
      BrowserModule,
      HttpClientModule,
      LandingModule,
      AngularSvgIconModule.forRoot(),
    ]

})
export class AppModule { }
