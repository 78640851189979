<header class="
    hidden
    py-4
    px-16
    lg:flex
    items-center
    bg-black
    bg-opacity-20
    fixed
    w-full
    z-10
    backdrop-blur-[10px]
    transition-[top]
    {{scrollDirection === 'down' ? '-top-full' : 'top-0'}}">
    <a routerLink="/">
        <img class="w-[45px]" src="./assets/images/landing/logo.svg" alt="4T">
    </a>
    <nav class="mx-auto">
        <ul class="flex">
            <li *ngFor="let link of nav" class="text-white p-[10px] mr-3 xl:mr-12 last:mr-0">
                <!-- <a class="cursor-pointer" (click)="scrollToEl(link.url)">{{link.text}}</a> -->
                <a *ngIf="link.url !== 'tokens'" class="cursor-pointer" [routerLink]="['./']" [fragment]="link.url">{{link.text}}</a>
                <a *ngIf="link.url === 'tokens'" class='cursor-pointer' routerLink="tokens">{{link.text}}</a>
            </li>
        </ul>
    </nav>
</header>
