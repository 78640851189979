<form class="form dialog__wrap" [formGroup]="keyForm" (ngSubmit)="onSubmit()">
  <h1 class="dialog__step" *ngIf="!isSendSmart">
    <button class="dialog__prevStep" type="button" (click)="onSubmit(true)">
      <img src="./assets/svg/icons/arrow.svg" alt="<">
    </button>
    <p>Step 3</p>
    <button class="dialog__nextStep" type="submit" [ngClass]="{'disabled' : keyForm.invalid}" [disabled]="keyForm.invalid">
      <img src="./assets/svg/icons/arrow.svg" alt=">"
      ></button>
  </h1>

  <h1 class="dialog__step" *ngIf="isSendSmart">
    <button class="dialog__prevStep" type="button" (click)="onSubmit(true)">
      <img src="./assets/svg/icons/arrow.svg" alt="<">
    </button>
    <p>Step 9</p>
    <button class="dialog__nextStep" type="submit" [ngClass]="{'disabled' : keyForm.invalid}" [disabled]="keyForm.invalid">
      <img src="./assets/svg/icons/arrow.svg" alt=">"
      ></button>
  </h1>

  <h2 class="dialog__title mb-[14px]">Wallet Private key</h2>

  <img class="pointer-events-none select-none mx-auto" src="./assets/webp/key.png" alt="">

  <p class="form-field__caption">Private Key</p>
  <mat-form-field class="form-field check-when-valid" appearance="outline">
    <svg-icon src="./assets/svg/icons/key.svg" matTextPrefix></svg-icon>
    <!-- <button class="icon" type="button" (click)="showKey = !showKey" [ngClass]="showKey ? 'visibility' : 'visibility off'"></button> -->
    <input placeholder="Insert your Private Key" id="key" formControlName="key" matInput required [type]="showKey ? 'text' : 'password'">

    <button
      class="mat-mdc-form-field-clear"
      *ngIf="keyForm.invalid && (keyForm.dirty || keyForm.touched) && keyForm.value.key !== ''"
      (click)="keyForm.setValue({key: ''})">
      <svg-icon src="./assets/svg/icons/error.svg"></svg-icon>
    </button>

    <mat-error *ngIf="!error">{{error}}</mat-error>
  </mat-form-field>

  <!-- <button class="button stroke" type="submit" [ngClass]="{disabled: keyForm.invalid}">Generate</button> -->

  <div class="mt-auto">
    <app-btn class="md:w-full" type="submit" [disabled]="keyForm.invalid">Generate</app-btn>
  </div>
</form>
