import { Component } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {
  accordionData = [
    {id: 0, title: 'What is 4Tokens?', content: '<p>It is a no-code tokenization platform enabling token issuance in just several clicks.</p>'},
    {id: 1, title: 'How does it work?', content: '<p>Just follow this link and fill in the fields the information required. In case you wish to get familiar with the process first and then proceed, please see our step-by-step user`s manual.</p>'},
    {id: 2, title: 'What is the standard of tokens?', content: '<p>The newly issued tokens correspond to T10 standard.</p>'},
    {id: 3, title: 'Is there a waiting period?', content: '<p>After the transaction is signed, you will have to wait for a while for transaction processing. Then, you will be redirected to the screen with the information about the address where your tokens are stored.<br/>Please be informed that the address of the token generation contract is shown only once and you need to keep it. For security reasons, please store it in both paper and electronic forms.<br/>To complete, click <span class="font-semibold underline">Finish</span>.</p>'},
    {id: 4, title: 'What is the platform best suited for?', content: '<p>The service is designed in a way to accommodate each prospective user and business model. This way, it makes the list of potential applications of the platform almost endless. It can be practically anything.</p>'},
    {id: 5, title: 'How much does it cost?', content: '<p>The issuance of tokens is free of charge, except for a small network fee required to process the token generation transaction.</p>'},
    {id: 6, title: 'What are the benefits?', content: '<p>4Tokens is the cheapest and most user friendly monetization platform currently existing. You no longer need to resort to centralized (expensive) companies to monetize the value of your services and goods.</p>'},
    {id: 7, title: 'A common example backing tokenization', content: '<p>The most common example is content monetization. With the help of 4Tokens, you no longer have to pay tech giants like Facebook, Google, Apple and others to monetize your intellectual work. Just sell your writings in return for the issued token. The respective token can as well become the card for the premium content of the author who issued that token.</p>'},
    {id: 8, title: 'What are the competitors?', content: '<p>There are several competitors of 4Tokens, such as Polymath and ADDX. However, none of them provides no-code tokenization. At least, yet.</p>'}
  ]
}
