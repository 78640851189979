<div class="flex flex-col">
    <div class="
        bg-black2 
        pl-8
        pr-4
        py-2
        min-h-[88px]
        xl:px-12
        xl:py-6
        flex 
        justify-between 
        items-center
        border-solid 
        border-1
        border-b-0
        border-white 
        border-opacity-10
        cursor-pointer"
        (click)="toggleActive(id)">
        <h5 class="font-manrope font-extrabold text-white text-md leading-5 xl:text-xl">{{title}}</h5>
        <div class="ml-6 transition-all" [ngClass]="{'rotate-180': active === id}">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="40" y="40" width="40" height="40" rx="20" transform="rotate(-180 40 40)" fill="#181D28"/>
                <g clip-path="url(#clip0_2146_13359)">
                    <path d="M12.9991 15.9995C13.1305 15.9995 13.2606 16.0254 13.3819 16.0758C13.5033 16.1261 13.6136 16.1998 13.7064 16.2928L18.8204 21.4068C18.9752 21.5616 19.1589 21.6844 19.3612 21.7682C19.5634 21.852 19.7802 21.8951 19.9991 21.8951C20.218 21.8951 20.4348 21.852 20.637 21.7682C20.8392 21.6844 21.023 21.5616 21.1778 21.4068L26.2858 16.2988C26.4744 16.1167 26.727 16.0159 26.9892 16.0181C27.2514 16.0204 27.5022 16.1256 27.6876 16.311C27.873 16.4964 27.9782 16.7472 27.9804 17.0094C27.9827 17.2716 27.8819 17.5242 27.6998 17.7128L22.5918 22.8208C21.9037 23.5076 20.9713 23.8933 19.9991 23.8933C19.0269 23.8933 18.0945 23.5076 17.4064 22.8208L12.2918 17.7061C12.152 17.5663 12.0569 17.3881 12.0184 17.1942C11.9799 17.0002 11.9998 16.7993 12.0755 16.6166C12.1512 16.434 12.2793 16.2779 12.4437 16.168C12.6081 16.0582 12.8014 15.9995 12.9991 15.9995Z" fill="#848994"/>
                </g>
                <defs>
                    <clipPath id="clip0_2146_13359">
                        <rect width="16" height="16" fill="white" transform="translate(28 28) rotate(-180)"/>
                    </clipPath>
                </defs>
            </svg>
        </div>
    </div>
    <div #wrapRef style="height: 0px;" class="overflow-hidden transition-all duration-300">
        <div #contentRef class="bg-white p-8 xl:p-12">
            <div *ngIf="id === 1; else defaultContentTemplate" class="col-span-6 text-md xl:text-xl">
                <p>Just follow this <app-home-page class="font-semibold underline">link</app-home-page> and fill in the fields the information required. In case you wish to get familiar with the process first and then proceed, please see our step-by-step <a class="font-semibold underline" href="https://helper.ubikiri.com/4tokens/usermanual.pdf" target="_blank">user's manual</a>.</p>
            </div>
            <ng-template #defaultContentTemplate>
                <div class="col-span-6 text-md xl:text-xl" [innerHTML]="content"></div>
            </ng-template>
        </div>
    </div>
</div>
