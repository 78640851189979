/** Wallet model */
export class WalletViewModel implements IWalletViewModel {
  /** Profile id */
  profileId?: number | undefined;
  /** Coint type */
  coinType?: WalletViewModelCoinType | undefined;
  /** Wallet name */
  name?: string | undefined;
  /** Wallet hash */
  hashAddress?: string | undefined;

  constructor(data?: IWalletViewModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.profileId = _data["profileId"];
      this.coinType = _data["coinType"];
      this.name = _data["name"];
      this.hashAddress = _data["hashAddress"];
    }
  }

  static fromJS(data: any): WalletViewModel {
    data = typeof data === 'object' ? data : {};
    let result = new WalletViewModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["profileId"] = this.profileId;
    data["coinType"] = this.coinType;
    data["name"] = this.name;
    data["hashAddress"] = this.hashAddress;
    return data;
  }
}

/** Wallet model */
export interface IWalletViewModel {
  /** Profile id */
  profileId?: number | undefined;
  /** Coint type */
  coinType?: WalletViewModelCoinType | undefined;
  /** Wallet name */
  name?: string | undefined;
  /** Wallet hash */
  hashAddress?: string | undefined;
}

export enum WalletViewModelCoinType {
  Cil = "Cil",
  Eth = "Eth",
}
