<div class="support__chat">
  <div class="support__header">
    <button
      *ngIf="mobileDetect.phone()"
      class="support__chatClose support__chatClose--mobile"
      (click)="closeClick()">
      <img src="/assets/svg/icons/support/back.svg" alt="←">
    </button>

    <span>Support Chat</span>

    <button class="support__assistance" (click)="createDialog.emit()" *ngIf="false">
      <img src="/assets/svg/icons/support/plus.svg" alt="+">
      Request assistance
    </button>

    <button *ngIf="!mobileDetect.phone()" class="support__chatClose" (click)="closeClick()">
      <img src="/assets/svg/icons/support/close.svg" alt="⨉">
    </button>
  </div>

  <div *ngIf="(dialogMessages?.length ?? 0) > 0" #messages class="support__messageList support--scrollable">

    <div *ngFor="let message of dialogMessages" [ngClass]="{'message':true,'message--sent':message.Sender === userEmail}">
      <div class="message__avatar"></div>
      <div class="message__body">
        <div class="message__sender">{{message.Sender}}</div>
        <div class="message__text">{{message.Text}}</div>
        <div class="message__date">{{message.CreationDate | date:'dd:MM:YYYY HH:mm:ss'}}</div>
      </div>
    </div>

    <!-- Start a chat message -->
    <div *ngIf="false" class="support__start">Start by asking your questions here,<br/> our support team will be in touch shortly!</div>

  </div>

  <div *ngIf="true" class="support__input">
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <input
        [formControl]="messageTextForm"
        matInput
        placeholder="Message"
        >

<!--      <button matSuffix mat-icon-button>-->
<!--        <img src="/assets/svg/icons/support/clip.svg" alt="Attachment">-->
<!--      </button>-->

      <button matSuffix mat-icon-button (click)="onSendMessage()">
        <img src="/assets/svg/icons/support/send.svg" alt="Send">
      </button>
    </mat-form-field>
  </div>

  <!-- Rate screen -->
  <div *ngIf="false" class="support__rate">
    <div class="rate">
      <h4>How do you rate<br/> this answer?</h4>

      <div class="rate__stars">
        <!-- 5 -->
        <div class="rate__star">
          <svg xmlns="http://www.w3.org/2000/svg" width="65" height="62" viewBox="0 0 65 62" fill="transparent">
            <path d="M37.043 4.48858C35.2584 0.60638 29.7416 0.606382 27.957 4.48858L22.2529 16.8973C21.8159 17.8478 20.9154 18.5021 19.8764 18.6239L6.31236 20.2143C2.0687 20.7119 0.363918 25.9587 3.50464 28.8556L13.5434 38.1151C14.3123 38.8243 14.6563 39.883 14.4511 40.9087L11.7722 54.3004C10.934 58.4902 15.3972 61.7328 19.1229 59.641L31.0313 52.955C31.9435 52.4428 33.0565 52.4428 33.9687 52.955L45.8771 59.641C49.6028 61.7328 54.066 58.4902 53.2278 54.3004L50.5489 40.9087C50.3437 39.883 50.6877 38.8243 51.4566 38.1151L61.4954 28.8556C64.6361 25.9587 62.9313 20.7119 58.6876 20.2143L45.1236 18.6239C44.0846 18.5021 43.1841 17.8478 42.7471 16.8973L37.043 4.48858Z" stroke="#F2BB6C" stroke-width="2"/>
          </svg>
        </div>
        <!-- 4 -->
        <div class="rate__star">
          <svg xmlns="http://www.w3.org/2000/svg" width="65" height="62" viewBox="0 0 65 62" fill="transparent">
            <path d="M37.043 4.48858C35.2584 0.60638 29.7416 0.606382 27.957 4.48858L22.2529 16.8973C21.8159 17.8478 20.9154 18.5021 19.8764 18.6239L6.31236 20.2143C2.0687 20.7119 0.363918 25.9587 3.50464 28.8556L13.5434 38.1151C14.3123 38.8243 14.6563 39.883 14.4511 40.9087L11.7722 54.3004C10.934 58.4902 15.3972 61.7328 19.1229 59.641L31.0313 52.955C31.9435 52.4428 33.0565 52.4428 33.9687 52.955L45.8771 59.641C49.6028 61.7328 54.066 58.4902 53.2278 54.3004L50.5489 40.9087C50.3437 39.883 50.6877 38.8243 51.4566 38.1151L61.4954 28.8556C64.6361 25.9587 62.9313 20.7119 58.6876 20.2143L45.1236 18.6239C44.0846 18.5021 43.1841 17.8478 42.7471 16.8973L37.043 4.48858Z" stroke="#F2BB6C" stroke-width="2"/>
          </svg>
        </div>
        <!-- 3 -->
        <div class="rate__star rate__star--active">
          <svg xmlns="http://www.w3.org/2000/svg" width="65" height="62" viewBox="0 0 65 62" fill="transparent">
            <path d="M37.043 4.48858C35.2584 0.60638 29.7416 0.606382 27.957 4.48858L22.2529 16.8973C21.8159 17.8478 20.9154 18.5021 19.8764 18.6239L6.31236 20.2143C2.0687 20.7119 0.363918 25.9587 3.50464 28.8556L13.5434 38.1151C14.3123 38.8243 14.6563 39.883 14.4511 40.9087L11.7722 54.3004C10.934 58.4902 15.3972 61.7328 19.1229 59.641L31.0313 52.955C31.9435 52.4428 33.0565 52.4428 33.9687 52.955L45.8771 59.641C49.6028 61.7328 54.066 58.4902 53.2278 54.3004L50.5489 40.9087C50.3437 39.883 50.6877 38.8243 51.4566 38.1151L61.4954 28.8556C64.6361 25.9587 62.9313 20.7119 58.6876 20.2143L45.1236 18.6239C44.0846 18.5021 43.1841 17.8478 42.7471 16.8973L37.043 4.48858Z" stroke="#F2BB6C" stroke-width="2"/>
          </svg>
        </div>
        <!-- 2 -->
        <div class="rate__star">
          <svg xmlns="http://www.w3.org/2000/svg" width="65" height="62" viewBox="0 0 65 62" fill="transparent">
            <path d="M37.043 4.48858C35.2584 0.60638 29.7416 0.606382 27.957 4.48858L22.2529 16.8973C21.8159 17.8478 20.9154 18.5021 19.8764 18.6239L6.31236 20.2143C2.0687 20.7119 0.363918 25.9587 3.50464 28.8556L13.5434 38.1151C14.3123 38.8243 14.6563 39.883 14.4511 40.9087L11.7722 54.3004C10.934 58.4902 15.3972 61.7328 19.1229 59.641L31.0313 52.955C31.9435 52.4428 33.0565 52.4428 33.9687 52.955L45.8771 59.641C49.6028 61.7328 54.066 58.4902 53.2278 54.3004L50.5489 40.9087C50.3437 39.883 50.6877 38.8243 51.4566 38.1151L61.4954 28.8556C64.6361 25.9587 62.9313 20.7119 58.6876 20.2143L45.1236 18.6239C44.0846 18.5021 43.1841 17.8478 42.7471 16.8973L37.043 4.48858Z" stroke="#F2BB6C" stroke-width="2"/>
          </svg>
        </div>
        <!-- 1 -->
        <div class="rate__star">
          <svg xmlns="http://www.w3.org/2000/svg" width="65" height="62" viewBox="0 0 65 62" fill="transparent">
            <path d="M37.043 4.48858C35.2584 0.60638 29.7416 0.606382 27.957 4.48858L22.2529 16.8973C21.8159 17.8478 20.9154 18.5021 19.8764 18.6239L6.31236 20.2143C2.0687 20.7119 0.363918 25.9587 3.50464 28.8556L13.5434 38.1151C14.3123 38.8243 14.6563 39.883 14.4511 40.9087L11.7722 54.3004C10.934 58.4902 15.3972 61.7328 19.1229 59.641L31.0313 52.955C31.9435 52.4428 33.0565 52.4428 33.9687 52.955L45.8771 59.641C49.6028 61.7328 54.066 58.4902 53.2278 54.3004L50.5489 40.9087C50.3437 39.883 50.6877 38.8243 51.4566 38.1151L61.4954 28.8556C64.6361 25.9587 62.9313 20.7119 58.6876 20.2143L45.1236 18.6239C44.0846 18.5021 43.1841 17.8478 42.7471 16.8973L37.043 4.48858Z" stroke="#F2BB6C" stroke-width="2"/>
          </svg>
        </div>
      </div>

      <button class="support__btn">
        Send your answer
      </button>
    </div>
  </div>
</div>
