import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent {
  @Input() data: {id: number, title: string, content: string}[] = []

  active: number | null = null

  toggle = (id: number | null) => {
    
    if (id === this.active) {
      this.active = null
    }
    else {
      this.active = id
    }
  }
}
