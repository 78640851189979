import { Component } from '@angular/core';

@Component({
  selector: 'app-rectangles-top',
  templateUrl: './rectangles-top.component.html',
  styleUrls: ['./rectangles-top.component.scss']
})
export class RectanglesTopComponent {

}
