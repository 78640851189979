import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
  AdminExchangeTokenResult,
  AdminTokenStatus, MessageType
} from "../../../home-page/components/SocialNetworkClient";

@Component({
  selector: 'app-token-info-box',
  templateUrl: './token-info-box.component.html',
  styleUrls: ['./token-info-box.component.scss']
})
export class TokenInfoBoxComponent {

  token: AdminExchangeTokenResult;
  is_normal_token = false;
  is_fake_token = false;
  tickerName: string;

  constructor(public dialogRef: MatDialogRef<TokenInfoBoxComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.token = data.token;
    this.tickerName = (this.token.ticker !== undefined && this.token.ticker.length > 0) ? this.token.ticker! : this.token.currency!;
    this.is_normal_token =  (this.token.status === AdminTokenStatus.Normal);
    this.is_fake_token =  (this.token.status === AdminTokenStatus.Fake);
  }

  closeClick(): void {
    this.dialogRef.close();
  }

  // Compliant for NORMAL token
  clickSendCompliant() {
    const msg = 'Make a complaint for token "' + this.tickerName.toUpperCase() + '"';
    this.dialogRef.close({currency: this.token.currency, type: MessageType.Complaint, message: msg});
  }

  // Decision for FAKE token
  clickSendDecision() {
    const msg = 'Appeal the decision for fake token "' + this.tickerName.toUpperCase() + '"';
    this.dialogRef.close({currency: this.token.currency, type: MessageType.Decision, message: msg});
  }

  getLogoString(logo: string | undefined | null): string {
    if (logo === undefined || logo === null || logo.length === 0) {
      return '(not set)';
    } else {
      return 'installed';
    }
  }

  getTagsString(tags: string[] | undefined | null): string {
    if (tags === undefined || tags === null || tags.length === 0) {
      return '';
    } else {
      let returnValue = ''
      for (const tag of tags) {
        returnValue += '@' + tag + ' ';
      }
      return returnValue;
    }
  }


  protected readonly AdminTokenStatus = AdminTokenStatus;
}
