import { Component, ElementRef, ViewChild, Input } from '@angular/core';

@Component({
  selector: 'app-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss']
})
export class AccordionItemComponent {
  @Input() id: number | null = null;
  @Input() title?: string;
  @Input() content?: string;
  @Input() 
    get active(): number | null {return this._active}
    set active(active: number | null) {
      this._active = active
      if (this.wrapRef && this.wrapRef.nativeElement) {
      
        if (this.id === active) {
          this.wrapRef.nativeElement.style.height = this.contentRef?.nativeElement.offsetHeight + 'px'
        }
  
        else {
          this.wrapRef.nativeElement.style.height = '0px'
        }
      }
    }
    private _active: number | null = null
  @Input() toggleActive: (id: number | null) => void = (id: number | null) => {}

  @ViewChild('wrapRef') wrapRef: ElementRef<HTMLDivElement> | null = null;
  @ViewChild('contentRef') contentRef: ElementRef<HTMLDivElement> | null = null;
  constructor(private hostElement: ElementRef) {
    hostElement.nativeElement.classList = '[&>div]:last:flex-col-reverse [&>div>div:first-child]:last:border-t-none [&>div>div:first-child]:last:rounded-b-2xl [&>div>div:first-child]:first:rounded-t-2xl [&>div>div:first-child]:last:border-b-1'
  }

  opened = false
}
