import { Component } from '@angular/core';

@Component({
  selector: 'app-rectangles-bottom',
  templateUrl: './rectangles-bottom.component.html',
  styleUrls: ['./rectangles-bottom.component.scss']
})
export class RectanglesBottomComponent {

}
