import { Component } from '@angular/core';

@Component({
  selector: 'app-ecosystem-section',
  templateUrl: './ecosystem-section.component.html'
})
export class EcosystemSectionComponent {
  openExchange() {
    window.open('https://ubix.exchange', '_blank');
  }
}
