<div class="pt-[65px] md:pt-[170px] xl:pt-[270px] relative">
    <div id="use-cases" class="pt-[80px] md:pt-[80px] xl:pt-[80px] pb-[190px] md:pb-[220px] xl:pb-[290px] relative overflow-x-clip">
        <div>
            <div class='absolute top-[-100px] pt-[250px] left-0 flex justify-center w-full h-[1800px] z-0 overflow-hidden'>
            <app-gradient-bg></app-gradient-bg>
        </div>
        <img class="absolute hidden md:block left-0 bottom-[190px] z-[-1]" src="./assets/images/landing/use-cases-section/rect2.svg" alt="">
        <app-container>
            <div class="relative">
                <img class="absolute opacity-20 md:opacity-100 left-[-168px] top-[200px] md:top-[-10px] z-[-1]" src="./assets/images/landing/use-cases-section/rect1.svg" alt="">
                <img class="absolute opacity-20 md:opacity-100 bottom-4 right-[-133px] z-[-1]" src="./assets/images/landing/use-cases-section/rect3.svg" alt="">
                <app-card class="bg-[transparent] border-none md:border-solid shadow-[none] md:shadow-card p-0 md:bg-black">
                    <app-card class="bg-black p-4 md:p-4 mb-12">
                        <h1 class="font-manrope font-semibold text-white text-5xl text-center">
                            <b class="text-blue4">Use</b> Cases
                        </h1>
                    </app-card> 
                    
                    <div class="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3">
                        <a 
                            *ngFor="let item of useCasesData"  
                            [routerLink]="item.id" 
                            class="block">
                            <app-card  class="group h-full md:pr-4 hover:shadow-glow hover:border-blue4 transition-all duration-75">
                                <div class="
                                    bg-blue2 text-white
                                    rounded-full 
                                    h-20 w-20 
                                    flex justify-center items-center 
                                    mb-6
                                    group-hover:bg-blue4 group-hover:text-black2
                                    transition-all duration-75">
                                    <svg-icon src={{item.img}}></svg-icon>
                                </div>
                                <h3 class="text-white font-manrope font-extrabold text-2xl mb-2">{{item.title}}</h3>
                                <p class="text-grey text-md">{{item.descr}}</p>
                            </app-card>
                        </a>
                    </div>
                </app-card>
            </div>
        </app-container>
    </div>
</div>
