import { NgModule } from '@angular/core';
import {NoPreloading, RouterModule, Routes} from '@angular/router';
import {MainLayoutComponent} from "./shared/components/main-layout/main-layout.component";
import {environment} from "../environments/environment";

const routes: Routes = [

  {
    path: environment.adminUrl, loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: '', loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule)
  },
  // {
  //   path: '', component: MainLayoutComponent, children: [
  //     {path: '', redirectTo: '/', pathMatch: 'full'},
  //     {path: '', loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule)}
  //   ],
  // },

];

@NgModule({
  imports: [RouterModule.forRoot(routes
    , {
    preloadingStrategy: NoPreloading,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
  }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
