<div class="loader-wrap" *ngIf="loading | async">
  <div class="loader-el"></div>
</div>
<div class="dialog__content">
  <div class="flex mb-5">
    <button class="dialog__close" (click)="closeClick()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <ng-container *ngIf="isAccessTypeSelected; then isInputPassword ? keystoreTemplate : privateKeyTemplate; else accessOptionsTemplate"></ng-container>
</div>
<ng-template #accessOptionsTemplate>
  <div class="dialog__wrap">

    <h1 class="dialog__step" *ngIf="data && !data.isSendSmart">
      <button class="dialog__prevStep" (click)="closeClick(true)">
        <img src="./assets/svg/icons/arrow.svg" alt="<">
      </button>
      <p>Step 2</p>
      <button class="dialog__nextStep" (click)="getAccess()" [ngClass]="{'disabled' : selectedAccessType.isEmpty()}" [disabled]="selectedAccessType.isEmpty()">
        <img src="./assets/svg/icons/arrow.svg" alt=">"
        ></button>
    </h1>

    <h1 class="dialog__step" *ngIf="data && data.isSendSmart">
      <button class="dialog__prevStep" (click)="closeClick(true)">
        <img src="./assets/svg/icons/arrow.svg" alt="<">
      </button>
      <p>Step 8</p>
      <button class="dialog__nextStep" (click)="getAccess()" [ngClass]="{'disabled' : selectedAccessType.isEmpty()}" [disabled]="selectedAccessType.isEmpty()">
        <img src="./assets/svg/icons/arrow.svg" alt=">"
        ></button>
    </h1>

    <h2 class="dialog__title mb-[14px]">{{data.isImport ? 'Import Wallet' : 'Sign transaction'}}</h2>
    <p class="dialog__subhead">Choose your keystore file or enter your private key and click «Continue»</p>
    <!-- <div class="form-field" [ngClass]="{'selected': selectedAccessType.isSelected(walletAccessType.KeyStore)}">
      <svg-icon svgClass="text-grey" src="./assets/svg/icons/document.svg" alt=""></svg-icon>
      <button class="button stroke key-file" type="button" (click)="selectedAccessType.toggle(walletAccessType.KeyStore)">Keystore File</button>
    </div> -->
    <div class="mt-auto">
      <div class="grid grid-cols-2 gap-4">

        <button type="button" (click)="selectedAccessType.toggle(walletAccessType.PrivateKey)">
          <app-dialog-card
            class="text-grey rounded-lg h-[148px] hover:shadow-glow hover:border-blue4 hover:text-blue4 flex flex-col justify-center items-center transition-all duration-75"
            [class]="{'selected': selectedAccessType.isSelected(walletAccessType.PrivateKey)}">
            <svg-icon src="./assets/svg/icons/key.svg"></svg-icon>
            <p class="mt-2">Private Key</p>
          </app-dialog-card>
        </button>

        <button type="button" (click)="selectedAccessType.toggle(walletAccessType.KeyStore)">
          <app-dialog-card
            class="p-0 md:p-0 text-grey rounded-lg h-[148px] hover:shadow-glow hover:border-blue4 hover:text-blue4 flex flex-col justify-center items-center transition-all duration-75"
            [class]="{'selected': selectedAccessType.isSelected(walletAccessType.KeyStore)}">
            <svg-icon src="./assets/svg/icons/document.svg"></svg-icon>
            <p class="mt-2">Keystore File</p>
          </app-dialog-card>
        </button>
      </div>

      <mat-error *ngIf="error">
        <p>{{error}}</p>
      </mat-error>

      <app-btn class="md:w-full mt-12" (onClick)="getAccess()" [disabled]="selectedAccessType.isEmpty()">Continue</app-btn>
      <!-- <button class="button stroke key-button" type="button"  [ngClass]="{disabled: selectedAccessType.isEmpty()}"></button> -->
      <input type="file" name="files" style="display: none" #fileUploader="" (change)="onFileChange($event)"/>
    </div>

  </div>
</ng-template>
<ng-template #keystoreTemplate>
  <app-access-with-keystore (password)="accessWithKeyStore($event)" [error]="error" [isSendSmart]="data.isSendSmart"></app-access-with-keystore>
</ng-template>
<ng-template #privateKeyTemplate>
  <app-access-with-key (key)="accessWithPrivateKey($event)" [error]="error" [isSendSmart]="data.isSendSmart"></app-access-with-key>
</ng-template>
