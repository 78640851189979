<div class="relative pt-[5px] md:pt-[120px] xl:pt-[257px]">
    <div id="how-it-works" class="relative pt-[80px] md:pt-[80px] xl:pt-[80px] overflow-x-clip">
        <img 
            class="absolute top-0 left-0 z-10 md:hidden"
            src="./assets/images/landing/how-it-works-section/rect1.svg" 
            alt="">
        <div class="absolute top-[-140px] left-0 hidden md:flex justify-start w-full h-[2342px] z-[-1] overflow-hidden">
            <img src="./assets/images/landing/how-it-works-section/circles.svg" alt="">
        </div>
        <app-container>
            <div class="relative z-[1]">
                <div class="
                    bg-black2 
                    p-8
                    xl:p-12 
                    border-solid
                    border-[1.5px]
                    border-grey
                    border-opacity-20
                    rounded-2xl
                    shadow-card
                    relative
                    grid gap-8 grid-rows-[auto,auto] md:grid-rows-none md:grid-cols-12 items-center">
                    <div class="md:col-span-7">
                        <h2 class="text-white font-extrabold font-manrope mb-4 text-4xl xl:text-5xl">How does it work?</h2>
                        <p class="text-grey text-md xl:text-xl mb-8 xl:mb-12">To issue T10 tokens on UBIX Network, just import your Übikiri wallet and enter the data - ticker, emission volume, and the decimal. Also, you can leave a text description (1-2 lines). The issued tokens will immediately appear on the wallet balance from which the transaction was paid out. <b>None of the steps require any programming skills.</b></p>
                        
                        <app-home-page>
                            <app-btn [gradient]="true" class="md:w-full">Issue your token</app-btn>
                        </app-home-page>
                    </div>
                    <img class="max-w-[170px] md:max-w-none md:col-span-5 m-auto order-first md:order-none" src="./assets/images/landing/logo_color.svg" alt="4T">
                </div>
                <img 
                    class="absolute bottom-[-105px] right-[-170px] z-[-1]"
                    src="./assets/images/landing/how-it-works-section/rect2.svg" 
                    alt="">
            </div>
        </app-container>
    </div>
</div>