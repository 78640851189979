<form class="form dialog__wrap" [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
  <h1 class="dialog__step" *ngIf="!isSendSmart">
    <button class="dialog__prevStep" type="button" (click)="onSubmit(true)">
      <img src="./assets/svg/icons/arrow.svg" alt="<">
    </button>
    <p>Step 3</p>
    <button class="dialog__nextStep" type="submit" [ngClass]="{'disabled' : passwordForm.invalid}" [disabled]="passwordForm.invalid">
      <img src="./assets/svg/icons/arrow.svg" alt=">">
    </button>
  </h1>

  <h1 class="dialog__step" *ngIf="isSendSmart">
    <button class="dialog__prevStep" type="button" (click)="onSubmit(true)">
      <img src="./assets/svg/icons/arrow.svg" alt="<">
    </button>
    <p>Step 9</p>
    <button class="dialog__nextStep" type="submit" [ngClass]="{'disabled' : passwordForm.invalid}" [disabled]="passwordForm.invalid">
      <img src="./assets/svg/icons/arrow.svg" alt=">">
    </button>
  </h1>

  <h2 class="dialog__title mb-[14px]">Keystore File Password</h2>

  <img class="pointer-events-none select-none mx-auto" src="./assets/webp/lock.png" alt="">

  <p class="form-field__caption">Password</p>
  <mat-form-field class="form-field" appearance="outline">
    <svg-icon src="./assets/svg/icons/lock.svg" matTextPrefix></svg-icon>
    <!-- <button class="icon" type="button" (click)="showPwd = !showPwd" [ngClass]="showPwd ? 'visibility' : 'visibility off'"></button> -->
    <input class="input" matInput [type]="showPwd ? 'text' : 'password'" required placeholder="Enter your password" formControlName="password" (keyup)="changePassword()"/>

    <button
      class="mat-mdc-form-field-clear"
      *ngIf="(passwordForm.dirty || passwordForm.touched) && passwordForm.value.password !== ''"
      (click)="changePassword('')">
      <svg-icon src="./assets/svg/icons/error.svg"></svg-icon>
    </button>

    <mat-error *ngIf="error">{{error}}</mat-error>
  </mat-form-field>

  <!-- <button class="button stroke" type="submit" [ngClass]="{disabled: passwordForm.invalid}">Continue</button> -->
  <div class="mt-auto">
    <app-btn class="md:w-full" type="submit" [disabled]="passwordForm.invalid">Continue</app-btn>
    <app-btn-outline *ngIf="false" class="md:w-full mt-4">Cancel</app-btn-outline>
  </div>
</form>
