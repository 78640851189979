import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-access-with-key',
  templateUrl: './access-with-key.component.html',
  styleUrls: ['./access-with-key.component.sass']
})
export class AccessWithKeyComponent {

  showKey = false;

  @Input() error: string | undefined;
  @Input() isSendSmart: boolean | undefined;
  @Output() key = new EventEmitter<string>();

  keyForm = this.formBuilder.group({
    key: ['', [Validators.required, Validators.minLength(64)]]
  });

  constructor(private formBuilder: FormBuilder) {
  }

  onSubmit(isPrev: boolean = false) {
    if (isPrev){
      this.key.emit('');
    } else {
      if (this.keyForm.invalid) {
        return;
      }
      // @ts-ignore
      this.key.emit(this.keyForm.value.key);
    }
  }
}
