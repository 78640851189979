<div class="loader-wrap" *ngIf="loading | async">
  <div class="loader-el"></div>
</div>
<div class="dialog__content">
  <div class="flex mb-5">
    <button class="dialog__close" (click)="closeClick()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <form class="dialog__wrap" [formGroup]="walletForm" (ngSubmit)="onSubmit()">
    <h1 class="dialog__step">
      <button class="dialog__prevStep" type="button"  (click)="closeClick(true)">
        <img src="./assets/svg/icons/arrow.svg" alt="<">
      </button>
      <p>Step 4</p>
      <button class="dialog__nextStep" type="submit" [ngClass]="{'disabled' : !!(error | async)}" [disabled]="!!(error | async)">
        <img src="./assets/svg/icons/arrow.svg" alt=">">
      </button>
    </h1>
    <h2 class="dialog__title mb-12">Confirm Imported Wallet</h2>
    <app-dialog-card>
      <p class="text-grey flex mb-[10px]">
        <svg-icon svgClass="mr-2" src="./assets/svg/icons/wallet.svg"></svg-icon>
        Wallet Address:
      </p>
      <p class="text-white break-all">
        {{(data && data.hashAddress) ? data.hashAddress : ''}}
      </p>
    </app-dialog-card>
    <mat-error *ngIf="error | async"><span>{{error | async}}</span></mat-error>
    <!-- <button class="stroke button" type="submit" [ngClass]="{disabled: error | async}">Confirm</button> -->
    <app-btn class="md:w-full mt-auto" type="submit" [disabled]="!!(error | async)">Confirm</app-btn>
    <!-- <button class="clean button" type="button" (click)="closeClick()">Close token generation</button> -->
  </form>
</div>
