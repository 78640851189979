import { Component, ElementRef, AfterViewInit, ViewChild, OnDestroy, Input } from '@angular/core';
import { twMerge } from 'tailwind-merge';

@Component({
  selector: 'app-gradient-bg',
  templateUrl: './gradient-bg.component.html'
})
export class GradientBgComponent implements AfterViewInit, OnDestroy {
  @Input() forceStopAnimation?: boolean = false
  @ViewChild('gradientCanvas') canvasRef: ElementRef<HTMLCanvasElement> | null = null;

  defaultClasses: string = 'absolute overflow-visible mx-auto left-auto right-auto blur-[143px]'

  showAnimation: boolean = true

  observer: IntersectionObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.showAnimation = true
        this.animate(this.start)
      }
      else {
        this.showAnimation = false
      }
    })
  })


  c: CanvasRenderingContext2D | null = null
  w: number = 0
  h: number = 0

  constructor(private hostElement: ElementRef) {
    this.hostElement.nativeElement.classList = twMerge(this.hostElement.nativeElement.classList, this.defaultClasses)
  }

  ngAfterViewInit(): void {
    if (this.canvasRef === null) return
    
    this.observer.observe(this.canvasRef.nativeElement)
    
    const canvas = this.canvasRef?.nativeElement
    this.c = canvas.getContext('2d')
    this.w = canvas.width
    this.h = canvas.height

    // this.animate(this.start)
  }

  tick = (scale: number, scale1: number) => {
    if (!this.c) return
    
    this.c.clearRect(0, 0, this.w, this.h)
    this.c.setTransform(1, 0, 0, 1, 390, 200);

    this.c.fillStyle = `rgba(28, 109, 192, ${.9*scale})`
    this.c.ellipse(864, 412, 864, 299, 0, 0, 360)
    this.c.fill()

    this.c.fillStyle = "#FFFFFF";
    let storedTransform = this.c.getTransform();
    this.c.beginPath();
    this.c.moveTo(0, 411.89999);
    this.c.bezierCurveTo(0, 246.54593, 386.82596, 112.5, 864, 112.5);
    this.c.bezierCurveTo(1341.17407, 112.5, 1728, 246.54593, 1728, 411.89999);
    this.c.bezierCurveTo(1728, 577.25403, 1341.17407, 711.29999, 864, 711.29999);
    this.c.bezierCurveTo(386.82596, 711.29999, 0, 577.25403, 0, 411.89999);
    this.c.setTransform(storedTransform);

    this.c.beginPath();
    this.c.moveTo(1167, 438.70001);
    this.c.bezierCurveTo(1167, 281.35406, 1312.05969, 153.80002, 1491, 153.80002);
    this.c.bezierCurveTo(1669.94031, 153.80002, 1815, 281.35406, 1815, 438.70001);
    this.c.bezierCurveTo(1815, 596.04596, 1669.94031, 723.59998, 1491, 723.59998);
    this.c.bezierCurveTo(1312.05969, 723.59998, 1167, 596.04596, 1167, 438.70001);
    this.c.fillStyle = "rgba(50, 5, 178, 0.9)";
    this.c.fill("nonzero");

    storedTransform = this.c.getTransform();
    
    let d = 574
    this.c.setTransform(scale, 0, 0, scale, -(d*scale - d), -(this.h*scale/2 - this.h/2));
    this.c.beginPath();
    this.c.moveTo(284, 765.79999);
    this.c.bezierCurveTo(284, 626.45856, 412.49426, 513.5, 571, 513.5);
    this.c.bezierCurveTo(729.50574, 513.5, 858, 626.45856, 858, 765.79999);
    this.c.bezierCurveTo(858, 905.14142, 729.50574, 1018.09998, 571, 1018.09998);
    this.c.bezierCurveTo(412.49426, 1018.09998, 284, 905.14142, 284, 765.79999);
    this.c.fillStyle = "rgba(49, 33, 234, 0.9)";
    this.c.fill("nonzero");
    this.c.setTransform(storedTransform);
    this.c.beginPath();
    this.c.moveTo(299, 29);
    this.c.lineTo(41, 29);
    this.c.lineTo(41, 249);
    this.c.lineTo(299, 249);
    this.c.lineTo(299, 29);
    this.c.closePath();
    this.c.moveTo(299, 470);
    this.c.lineTo(41, 470);
    this.c.lineTo(41, 690);
    this.c.lineTo(299, 690);
    this.c.lineTo(299, 470);
    this.c.closePath();
    this.c.fillStyle = "rgba(34, 81, 252, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(556, 250);
    this.c.lineTo(299, 250);
    this.c.lineTo(299, 470);
    this.c.lineTo(556, 470);
    this.c.lineTo(556, 250);
    this.c.closePath();
    this.c.fillStyle = "rgba(18, 120, 230, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(814, 470);
    this.c.lineTo(556, 470);
    this.c.lineTo(556, 690);
    this.c.lineTo(814, 690);
    this.c.lineTo(814, 470);
    this.c.closePath();
    this.c.fillStyle = "rgba(20, 194, 254, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(583, 64);
    this.c.lineTo(325, 64);
    this.c.lineTo(325, 284);
    this.c.lineTo(583, 284);
    this.c.lineTo(583, 64);
    this.c.closePath();
    this.c.moveTo(583, 505);
    this.c.lineTo(325, 505);
    this.c.lineTo(325, 725);
    this.c.lineTo(583, 725);
    this.c.lineTo(583, 505);
    this.c.closePath();
    this.c.fillStyle = "rgba(34, 81, 252, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(840, 285);
    this.c.lineTo(583, 285);
    this.c.lineTo(583, 505);
    this.c.lineTo(840, 505);
    this.c.lineTo(840, 285);
    this.c.closePath();
    this.c.fillStyle = "rgba(18, 120, 230, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(1098, 505);
    this.c.lineTo(840, 505);
    this.c.lineTo(840, 725);
    this.c.lineTo(1098, 725);
    this.c.lineTo(1098, 505);
    this.c.closePath();
    this.c.fillStyle = "rgba(20, 194, 254, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(1016, 139);
    this.c.lineTo(853, 139);
    this.c.lineTo(853, 279);
    this.c.lineTo(1016, 279);
    this.c.lineTo(1016, 139);
    this.c.closePath();
    this.c.moveTo(1016, 418);
    this.c.lineTo(853, 418);
    this.c.lineTo(853, 557);
    this.c.lineTo(1016, 557);
    this.c.lineTo(1016, 418);
    this.c.closePath();
    this.c.fillStyle = "rgba(34, 81, 252, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(1179, 0);
    this.c.lineTo(1016, 0);
    this.c.lineTo(1016, 139);
    this.c.lineTo(1179, 139);
    this.c.lineTo(1179, 0);
    this.c.closePath();
    this.c.fillStyle = "rgba(95, 20, 254, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(1179, 279);
    this.c.lineTo(1016, 279);
    this.c.lineTo(1016, 418);
    this.c.lineTo(1179, 418);
    this.c.lineTo(1179, 279);
    this.c.closePath();
    this.c.fillStyle = "rgba(18, 120, 230, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(1342, 418);
    this.c.lineTo(1179, 418);
    this.c.lineTo(1179, 557);
    this.c.lineTo(1342, 557);
    this.c.lineTo(1342, 418);
    this.c.closePath();
    this.c.fillStyle = "rgba(20, 194, 254, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(1039, 297);
    this.c.lineTo(781, 297);
    this.c.lineTo(781, 517);
    this.c.lineTo(1039, 517);
    this.c.lineTo(1039, 297);
    this.c.closePath();
    this.c.moveTo(1039, 737);
    this.c.lineTo(781, 737);
    this.c.lineTo(781, 957);
    this.c.lineTo(1039, 957);
    this.c.lineTo(1039, 737);
    this.c.closePath();
    this.c.fillStyle = "rgba(34, 81, 252, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(1296, 77);
    this.c.lineTo(1039, 77);
    this.c.lineTo(1039, 297);
    this.c.lineTo(1296, 297);
    this.c.lineTo(1296, 77);
    this.c.closePath();
    this.c.fillStyle = "rgba(95, 20, 254, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(1296, 517);
    this.c.lineTo(1039, 517);
    this.c.lineTo(1039, 737);
    this.c.lineTo(1296, 737);
    this.c.lineTo(1296, 517);
    this.c.closePath();
    this.c.fillStyle = "rgba(18, 120, 230, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(1554, 737);
    this.c.lineTo(1296, 737);
    this.c.lineTo(1296, 957);
    this.c.lineTo(1554, 957);
    this.c.lineTo(1554, 737);
    this.c.closePath();
    this.c.fillStyle = "rgba(20, 194, 254, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(299, 29);
    this.c.lineTo(41, 29);
    this.c.lineTo(41, 249);
    this.c.lineTo(299, 249);
    this.c.lineTo(299, 29);
    this.c.closePath();
    this.c.moveTo(299, 470);
    this.c.lineTo(41, 470);
    this.c.lineTo(41, 690);
    this.c.lineTo(299, 690);
    this.c.lineTo(299, 470);
    this.c.closePath();
    this.c.fillStyle = "rgba(34, 81, 252, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(556, 250);
    this.c.lineTo(299, 250);
    this.c.lineTo(299, 470);
    this.c.lineTo(556, 470);
    this.c.lineTo(556, 250);
    this.c.closePath();
    this.c.fillStyle = "rgba(18, 120, 230, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(814, 470);
    this.c.lineTo(556, 470);
    this.c.lineTo(556, 690);
    this.c.lineTo(814, 690);
    this.c.lineTo(814, 470);
    this.c.closePath();
    this.c.fillStyle = "rgba(20, 194, 254, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(583, 64);
    this.c.lineTo(325, 64);
    this.c.lineTo(325, 284);
    this.c.lineTo(583, 284);
    this.c.lineTo(583, 64);
    this.c.closePath();
    this.c.moveTo(583, 505);
    this.c.lineTo(325, 505);
    this.c.lineTo(325, 725);
    this.c.lineTo(583, 725);
    this.c.lineTo(583, 505);
    this.c.closePath();
    this.c.fillStyle = "rgba(34, 81, 252, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(840, 285);
    this.c.lineTo(583, 285);
    this.c.lineTo(583, 505);
    this.c.lineTo(840, 505);
    this.c.lineTo(840, 285);
    this.c.closePath();
    this.c.fillStyle = "rgba(18, 120, 230, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(1098, 505);
    this.c.lineTo(840, 505);
    this.c.lineTo(840, 725);
    this.c.lineTo(1098, 725);
    this.c.lineTo(1098, 505);
    this.c.closePath();
    this.c.fillStyle = "rgba(95, 20, 254, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(1016, 139);
    this.c.lineTo(853, 139);
    this.c.lineTo(853, 279);
    this.c.lineTo(1016, 279);
    this.c.lineTo(1016, 139);
    this.c.closePath();
    this.c.moveTo(1016, 418);
    this.c.lineTo(853, 418);
    this.c.lineTo(853, 557);
    this.c.lineTo(1016, 557);
    this.c.lineTo(1016, 418);
    this.c.closePath();
    this.c.fillStyle = "rgba(34, 81, 252, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(1179, 0);
    this.c.lineTo(1016, 0);
    this.c.lineTo(1016, 139);
    this.c.lineTo(1179, 139);
    this.c.lineTo(1179, 0);
    this.c.closePath();
    this.c.fillStyle = "rgba(95, 20, 254, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(1179, 279);
    this.c.lineTo(1016, 279);
    this.c.lineTo(1016, 418);
    this.c.lineTo(1179, 418);
    this.c.lineTo(1179, 279);
    this.c.closePath();
    this.c.fillStyle = "rgba(18, 120, 230, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(1342, 418);
    this.c.lineTo(1179, 418);
    this.c.lineTo(1179, 557);
    this.c.lineTo(1342, 557);
    this.c.lineTo(1342, 418);
    this.c.closePath();
    this.c.fillStyle = "rgba(20, 194, 254, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(1039, 297);
    this.c.lineTo(781, 297);
    this.c.lineTo(781, 517);
    this.c.lineTo(1039, 517);
    this.c.lineTo(1039, 297);
    this.c.closePath();
    this.c.moveTo(1039, 737);
    this.c.lineTo(781, 737);
    this.c.lineTo(781, 957);
    this.c.lineTo(1039, 957);
    this.c.lineTo(1039, 737);
    this.c.closePath();
    this.c.fillStyle = "rgba(34, 81, 252, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(1296, 77);
    this.c.lineTo(1039, 77);
    this.c.lineTo(1039, 297);
    this.c.lineTo(1296, 297);
    this.c.lineTo(1296, 77);
    this.c.closePath();
    this.c.fillStyle = "rgba(95, 20, 254, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(1296, 517);
    this.c.lineTo(1039, 517);
    this.c.lineTo(1039, 737);
    this.c.lineTo(1296, 737);
    this.c.lineTo(1296, 517);
    this.c.closePath();
    this.c.fillStyle = "rgba(18, 120, 230, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(1554, 737);
    this.c.lineTo(1296, 737);
    this.c.lineTo(1296, 957);
    this.c.lineTo(1554, 957);
    this.c.lineTo(1554, 737);
    this.c.closePath();
    this.c.fillStyle = "rgba(20, 194, 254, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(340, 29);
    this.c.lineTo(82, 29);
    this.c.lineTo(82, 249);
    this.c.lineTo(340, 249);
    this.c.lineTo(340, 29);
    this.c.closePath();
    this.c.moveTo(340, 470);
    this.c.lineTo(82, 470);
    this.c.lineTo(82, 690);
    this.c.lineTo(340, 690);
    this.c.lineTo(340, 470);
    this.c.closePath();
    this.c.fillStyle = "rgba(34, 81, 252, 0.5)";
    this.c.fill("nonzero");
    
    d = 515
    let h2 = 440
    storedTransform = this.c.getTransform()
    this.c.setTransform(scale, 0, 0, scale, -(d*scale/2 - d), -(h2*scale/2 - h2))
    this.c.beginPath();
    this.c.moveTo(597, 250);
    this.c.lineTo(340, 250);
    this.c.lineTo(340, 470);
    this.c.lineTo(597, 470);
    this.c.lineTo(597, 250);
    this.c.closePath();
    this.c.fillStyle = "rgba(230, 18, 18, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(855, 470);
    this.c.lineTo(597, 470);
    this.c.lineTo(597, 690);
    this.c.lineTo(855, 690);
    this.c.lineTo(855, 470);
    this.c.closePath();
    this.c.setTransform(storedTransform)

    d = 258
    h2 = 220
    storedTransform = this.c.getTransform()
    this.c.scale(scale, scale)
    this.c.translate(-(d*scale - d), -(h2*scale - h2))
    this.c.fillStyle = "rgba(95, 20, 254, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(624, 64);
    this.c.lineTo(366, 64);
    this.c.lineTo(366, 284);
    this.c.lineTo(624, 284);
    this.c.lineTo(624, 64);
    this.c.closePath();
    this.c.scale(1/scale, 1/scale)
    this.c.translate((d*scale - d), (h2*scale - h2))
    // this.c.translate((d/2), (h2/2))

    this.c.fillStyle = "rgba(20, 194, 254, 0.9)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(624, 505);
    this.c.lineTo(366, 505);
    this.c.lineTo(366, 725);
    this.c.lineTo(624, 725);
    this.c.lineTo(624, 505);
    this.c.closePath();

    this.c.fillStyle = "rgba(34, 81, 252, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(881, 285);
    this.c.lineTo(624, 285);
    this.c.lineTo(624, 505);
    this.c.lineTo(881, 505);
    this.c.lineTo(881, 285);
    this.c.closePath();
    this.c.fillStyle = "rgba(249, 249, 239, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(1057, 139);
    this.c.lineTo(894, 139);
    this.c.lineTo(894, 279);
    this.c.lineTo(1057, 279);
    this.c.lineTo(1057, 139);
    this.c.closePath();
    this.c.moveTo(1057, 418);
    this.c.lineTo(894, 418);
    this.c.lineTo(894, 557);
    this.c.lineTo(1057, 557);
    this.c.lineTo(1057, 418);
    this.c.closePath();
    this.c.fillStyle = "rgba(34, 81, 252, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(1220, 0);
    this.c.lineTo(1057, 0);
    this.c.lineTo(1057, 139);
    this.c.lineTo(1220, 139);
    this.c.lineTo(1220, 0);
    this.c.closePath();
    this.c.fillStyle = "rgba(95, 20, 254, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(1220, 279);
    this.c.lineTo(1057, 279);
    this.c.lineTo(1057, 418);
    this.c.lineTo(1220, 418);
    this.c.lineTo(1220, 279);
    this.c.closePath();
    this.c.fillStyle = "rgba(18, 120, 230, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(1383, 418);
    this.c.lineTo(1220, 418);
    this.c.lineTo(1220, 557);
    this.c.lineTo(1383, 557);
    this.c.lineTo(1383, 418);
    this.c.closePath();
    this.c.fillStyle = "rgba(20, 194, 254, 0.5)";
    this.c.fill("nonzero");
    d = 773
    let h1 = 880
    storedTransform = this.c.getTransform();
    // this.c.setTransform(scale, 0, 0, scale, -(d*scale/2 - d/2), -(h1*scale/2 - h1/2));
    // this.c.setTransform(scale, 0, 0, scale, -(d*scale/2), -(h1*scale/2));
    this.c.scale(scale1, scale1)
    this.c.translate(-(d*scale1/2 - d/2), -(this.h*scale1/2 - this.h/2))

    this.c.beginPath();
    this.c.moveTo(1080, 297);
    this.c.lineTo(822, 297);
    this.c.lineTo(822, 517);
    this.c.lineTo(1080, 517);
    this.c.lineTo(1080, 297);
    this.c.closePath();
    this.c.moveTo(1080, 737);
    this.c.lineTo(822, 737);
    this.c.lineTo(822, 957);
    this.c.lineTo(1080, 957);
    this.c.lineTo(1080, 737);
    this.c.closePath();
    this.c.fillStyle = "rgba(34, 81, 252, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(1337, 77);
    this.c.lineTo(1080, 77);
    this.c.lineTo(1080, 297);
    this.c.lineTo(1337, 297);
    this.c.lineTo(1337, 77);
    this.c.closePath();
    this.c.fillStyle = "rgba(95, 20, 254, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(1337, 517);
    this.c.lineTo(1080, 517);
    this.c.lineTo(1080, 737);
    this.c.lineTo(1337, 737);
    this.c.lineTo(1337, 517);
    this.c.closePath();
    this.c.fillStyle = "rgba(14, 150, 44, 0.5)";
    this.c.fill("nonzero");
    this.c.beginPath();
    this.c.moveTo(1595, 737);
    this.c.lineTo(1337, 737);
    this.c.lineTo(1337, 957);
    this.c.lineTo(1595, 957);
    this.c.lineTo(1595, 737);
    this.c.closePath();
    this.c.fillStyle = "rgba(20, 194, 254, 0.5)";
    this.c.fill("nonzero");
    this.c.setTransform(storedTransform)

    this.c.beginPath();
    this.c.moveTo(-110, 336.70001);
    this.c.bezierCurveTo(-110, 221.16205, -3.44377, 127.50002, 128, 127.50002);
    this.c.bezierCurveTo(259.44379, 127.50002, 366, 221.16205, 366, 336.70001);
    this.c.bezierCurveTo(366, 452.23798, 259.44379, 545.90002, 128, 545.90002);
    this.c.bezierCurveTo(-3.44377, 545.90002, -110, 452.23798, -110, 336.70001);
    this.c.fillStyle = "rgba(83, 91, 94, 0.1)";
    this.c.fill("nonzero");
    this.c.setTransform(1, 0, 0, 1, 0, 0);
}

  start = 0;
  scale = .7;
  scale1 = .7
  step = .002
  // const k = .8
  l = this.step
  l1 = this.step
  animate = (timestamp: number) => {
    if (!this.showAnimation) return
    if (this.forceStopAnimation) return
    
    if (timestamp - this.start > 10) {
        this.start = timestamp
        this.tick(this.scale, this.scale1)
        if (this.scale >= 1.5) {
            this.l = -this.step
        }
        if (this.scale < .7) {
            this.l = this.step
        }

        if (this.scale1 >= 1.5) {
            this.l1 = -this.step
        }
        if (this.scale1 < .7) {
            this.l1 = this.step
        }
        
        this.scale = this.scale + this.l
        this.scale1 = this.scale1 + this.l1*.7
    }
    requestAnimationFrame(this.animate)
  }

  ngOnDestroy(): void {
    this.observer.disconnect()
  }
}
