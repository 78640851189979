import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {combineLatest, Observable, of, throwError} from 'rxjs';
import {filter, first, mergeMap} from 'rxjs/operators';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TransactionGenerationErrorComponent} from "../../components/access.to.wallet/transaction-generation-error/transaction-generation-error.component";
import {TransactionGenerationSuccessComponent} from "../../components/access.to.wallet/transaction-generation-success/transaction-generation-success.component";
import {CilSmartContractTransactionModel} from "../../models/transaction.model";
import {WalletDto} from "../../models/Wallets/WalletDto";
import {AccessToWalletComponent} from "../../components/access.to.wallet/access-to-wallet/access-to-wallet.component";
import {CilTransactionHelper} from "../../models/Cil/CilTransaction";
import {
  AdminTokenResult,
  SocialNetworkClient,
  TransactionViewModel
} from '../../components/SocialNetworkClient';
import {SendTransactionComponent} from "../send-transaction/send-transaction.component";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-send-confirm',
  templateUrl: './send-confirm.component.html',
  styleUrls: ['./send-confirm.component.sass']
})
export class SendConfirmComponent implements OnDestroy{

  @Input() tiket: string | undefined;
  @Input() name: string | undefined;
  @Input() description: string | undefined;
  @Input() decimals: number | undefined;
  @Input() contractAddress: string | undefined;
  @Input() emissionAmount: number | undefined;
  @Input() emissionAddress: string | undefined;
  @Input() logo: string | undefined;
  @Input() facebook: string | undefined;
  @Input() twitter: string | undefined;
  @Input() telegram: string | undefined;
  @Input() tags: string[] | undefined;
  @Input() units: string | undefined;
  @Input() cilSmartContractTransactionModel: Observable<CilSmartContractTransactionModel | null> | undefined;
  @Input() accountBalance: Observable<{value: number | undefined} | null> | undefined;
  @Input() dialogRef: MatDialogRef<SendTransactionComponent> | undefined;
  @Input() id: string | undefined;
  @Output() isGenerateTransaction = new EventEmitter<boolean>();
  @Output() loading = new EventEmitter<boolean>();

  interval: any;
  currentTimeout: any;

  constructor(
    protected socialNetworkClient: SocialNetworkClient,
    protected dialog: MatDialog,
    private snackBar: MatSnackBar,
    ) { }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }

    if (this.currentTimeout) {
      clearTimeout(this.currentTimeout);
    }

    try {
      this.snackBar.dismiss();
    } catch {}
  }

  openAccessWalletDialog(): void {
    try {
      this.snackBar.dismiss();
    } catch {}

    let request;
    request = this.cilSmartContractTransactionModel!
      .pipe(first())
      .pipe(mergeMap(data => this.dialog.open(AccessToWalletComponent, {
        panelClass: ['dialog'],
        width: '566px',
        maxWidth: '100vw',
        data: {coinType: data?.coinType, address: data?.addressFrom, isSendSmart: true}
      }).afterClosed()))
      .pipe(filter(result => !!result))
      .pipe(mergeMap(result => {
        this.loading.emit(true);
        if (result instanceof WalletDto) {
          return combineLatest(of(result), this.cilSmartContractTransactionModel!);
        } else {
          return throwError(result);
        }
      }))
      .pipe(mergeMap(([wallet, transaction]) =>
        this.processSmartContractTransaction(wallet, transaction!) //NO TOKEN HERE
      ));

    request
      .subscribe(() => {
        this.loading.emit(false);
        this.openSuccessDialog();
      }, (error) => {
        this.loading.emit(false);
        if (error !== true) {
          this.openErrorDialog(error);
        }
      });
  }

  private processSmartContractTransaction(wallet: WalletDto, transaction: CilSmartContractTransactionModel) {
    return this.sendCilSmartTransaction(wallet, transaction);
  }

  private sendCilSmartTransaction(wallet: WalletDto, transaction: CilSmartContractTransactionModel): Observable<void> {
    // @ts-ignore
    return this.socialNetworkClient.getWalletListUnspent(wallet.getAddress(), this.id!)
      .pipe(
        mergeMap(unspents => {

          // FILL CIL TRANSACTION
          const cilTran = CilTransactionHelper.getCilTran(wallet,
            environment.cilTokenProofAddress,
            environment.cilTokenRequiredAmount +
                    environment.cilTokenRequiredProof +
                    environment.cilTokenTransferAmount +
                    environment.cilTokenCreateTransferFees,
            unspents);

          const request = new TransactionViewModel({
            transaction: cilTran.encode().toString('hex'),
          });



          // FILL CREATE TOKEN TRANSACTION
          // const transactionParams = transaction.params.reduce(function(map, obj) {
          //   // @ts-ignore
          //   map[obj.name] = obj.value;
          //   return map;
          // }, {});
          // const cilSmartContractTran = CilTransactionHelper.getCilSmartContractTran(
          //   wallet, transaction.contractAddress, transaction.consiliumId, transaction.amount,
          //   unspents, transaction.method, [transactionParams]);
          // const request = new TransactionViewModel({
          //   transaction: cilSmartContractTran.encode().toString('hex'),
          // });

          const token = new AdminTokenResult({
            symbol: this.tiket,
            ticker: this.tiket,
            name: this.name,
            description: this.description,
            decimals: this.decimals,
            contractAddress: this.contractAddress,
            emissionAmount: this.emissionAmount,
            emissionAddress: this.emissionAddress,
            logo: this.logo,
            facebook: this.facebook,
            twitter: this.twitter,
            telegram: this.telegram,
            tags: this.tags,
            id: undefined,
            status: undefined,
            sourceCode: undefined,
            userAddress: undefined,
            stage: undefined,
            complete: undefined
          })

          return this.socialNetworkClient.sendTransaction(request, wallet.address!, token, this.id!);



        })
      );
  }

  private openErrorDialog(errMsg: string): void {
    this.snackBar.openFromComponent(TransactionGenerationErrorComponent, {
      verticalPosition: 'top',
      panelClass: 'error',
      // duration: 5000,
      data: {
        message: errMsg
      }
    })
  }

  private openSuccessDialog(): void {
    // this.dialog.open(TransactionGenerationSuccessComponent, {
    //   width: '440px',
    //   data: {
    //     message: 'Transaction generated successfully'
    //   }
    // })

    // !!! CHECK IF ITS WORKING WITH SNACKBAR INSTEAD OF DIALOG !!!
    this.snackBar.openFromComponent(TransactionGenerationSuccessComponent, {
      verticalPosition: 'top',
      panelClass: 'success',
      duration: 3000,
      data: {
        message: 'Transaction generated successfully'
      }
    }).afterDismissed().subscribe(() => {
      //ON LOADER FOR WAIT WHILE NEW TOKEN GENERATED
      this.loading.emit(true);
      //CHECK TOKEN IS GENERATED
      this.interval = setInterval(() => {
        this.socialNetworkClient.getCilTokenStatus(this.tiket, this.id!)
          .subscribe(
            result => {
              if (result !== undefined && result !== null) {
                if (result.isCreated !== undefined && result.isCreated !== null) {
                  // console.log('checkToken(tiket: string)', this.tiket, result.isCreated);
                  if (result.isCreated) {
                    //OFF LOADER
                    this.loading.emit(false);

                    clearInterval(this.interval);

                    //CLOSE DIALOG
                    this.dialogRef!.close(2); //OK
                  }

                }
              }
            });
      }, 5000);

      //START TIMER FOR CLOSE AFTER 1 MIN (if no token)
      this.currentTimeout =  setTimeout(() => {
        //OFF LOADER
        this.loading.emit(false);
        clearTimeout(this.currentTimeout);
        //CLOSE DIALOG
        this.dialogRef!.close(1); //NOK
      },120000);
    });
  }
}
