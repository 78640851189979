import {Component, ElementRef, ViewChild} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { WalletAccessType} from "../wallet-access.enum";
import { WalletDto} from "../../models/Wallets/WalletDto";
import { Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { DestroyComponent } from './destroy-component';
import {CilWalletDto} from "../../models/Wallets/CilWalletDto";

@Component({template: ''})
// tslint:disable-next-line:component-class-suffix
export abstract class BaseAccessToWallet extends DestroyComponent {
  @ViewChild('fileUploader') fileUploader: ElementRef | undefined;
  selectedAccessType = new SelectionModel<WalletAccessType>(false, []);
  walletAccessType = WalletAccessType;
  isInputPassword = false;
  isAccessTypeSelected = false;
  error: string | undefined = undefined;
  wallet: WalletDto | undefined;
  loading = new Subject();

  protected useCache = false;
  public abstract data: any;

  protected constructor(public dialogRef: MatDialogRef<BaseAccessToWallet>) {
    super();
  }

  protected abstract finishAction(): void;

  accessWithPrivateKey(privateKey: string): void {
    if (privateKey === '') {
      this.isInputPassword = false;
      this.isAccessTypeSelected = false;
    }
    else {
      try {
        this.wallet = new CilWalletDto(privateKey, null);

        if (this.wallet.getAddress() === this.data.address || this.data.isImport) {
          this.cacheKey();
          this.finishAction();
        } else {
          this.dialogRef.close('The Keystore file or Private key is not from this wallet.');
        }
      } catch (e) {
        this.dialogRef.close(e);
      }
    }
  }

  async accessWithKeyStore(password: string) {
    this.error = undefined;
    if (password === '') {
      this.isInputPassword = false;
      this.isAccessTypeSelected = false;
    } else {

      this.loading.next(true);
      const savedAddress = this.wallet?.getAddressFromKeyFile();
      try {
        // @ts-ignore
        await this.wallet.decrypt(password);
      } catch {
        this.loading.next(false);
        this.error = 'Password is incorrect';
        return;
      }

      // @ts-ignore
      if (this.wallet.getAddress() === this.data.address || this.data.isImport) {

        //Check if incorrect address
        if (this.wallet?.getAddress() !== savedAddress) {
          this.loading.next(false);
          this.error = 'Password is incorrect';  //Test case
          return;
        }

        this.cacheKey();
        this.finishAction();
      } else {
        this.loading.next(false);
        this.error = 'The Keystore file or Private key is not from this wallet.';
        this.dialogRef.close('The Keystore file or Private key is not from this wallet.');
      }

      this.error = undefined;
      this.loading.next(false);
    }
  }

  getAccess() {
    if (this.selectedAccessType.isSelected(WalletAccessType.PrivateKey)) {
      this.isAccessTypeSelected = true;
    }
    else {
      // @ts-ignore
      this.fileUploader.nativeElement.click();
    }
  }

  onFileChange(event: any) {
    this.error = undefined;

    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      this.loading.next(true);
      const [file] = event.target.files;
      reader.readAsText(file);

      reader.onload = async () => {
        try {
          // @ts-ignore
          let wallet = new CilWalletDto(null, reader.result.toString());

          try {
            if (wallet.hasHash && !await wallet.checkHash())
              throw new Error('The Keystore file was changed');
          } catch(e: any) {
            this.loading.next(false);
            this.error = e;
            return;
          }
          this.wallet = wallet;

          this.isInputPassword = true;
          this.isAccessTypeSelected = true;
        }
        catch(e) {
          this.dialogRef.close(e);
        }
        this.loading.next(false);
      };
    }
  }

  cacheKey() {
    if (this.useCache && this.wallet) {
      sessionStorage.setItem('wkp', <string>this.wallet.getPrivateKey());
    }
  }

  closeClick(isPrev: boolean = false): void {
    this.dialogRef.close(isPrev);
  }
}
