import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BaseAccessToWallet} from '../BaseAccessToWallet';

@Component({
  selector: 'app-access-to-wallet',
  templateUrl: './access-to-wallet.component.html',
  styleUrls: ['./access-to-wallet.component.sass']
})

export class AccessToWalletComponent extends BaseAccessToWallet {

  @Output() success = new EventEmitter<boolean>();

  constructor(
    public override dialogRef: MatDialogRef<AccessToWalletComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    super(dialogRef);
    this.data = data;

  }

  protected finishAction(): void {
    this.dialogRef.close(this.wallet);
  }
}
