import {Component, Output, ViewChild, ElementRef, EventEmitter, Input, OnInit} from '@angular/core';
import * as MobileDetect from 'mobile-detect';
import { SupportChatMessageView } from "../../../models/Chat/SupportChatMessageView";
import { SupportChatDialogView } from "../../../models/Chat/supportChatDialogView";
import { FormControl, Validators } from "@angular/forms";
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'app-support-chat',
  templateUrl: './support-chat.component.html',
  styleUrls: ['./support-chat.component.scss']
})
export class SupportChatComponent implements OnInit{

  @Input() dialogMessages: SupportChatMessageView[] | undefined;
  @Input() activeDialog: SupportChatDialogView | null | undefined;
  @Input() currentUser: BehaviorSubject<string | null> | undefined;

  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() sendMessage: EventEmitter<any> = new EventEmitter();
  @Output() createDialog: EventEmitter<any> = new EventEmitter();

  @ViewChild('messages') messagesEl!: ElementRef;

  mobileDetect = new MobileDetect(window.navigator.userAgent);
  messageTextForm: FormControl = new FormControl(null, [Validators.required]);

  userEmail: string| null = null;

  ngOnInit() {
    this.currentUser?.subscribe(s => {

      if (s) {
        this.userEmail = s;
      }
    })
  }


  closeClick(): void {
    this.onClose.emit()
  }

  onSendMessage() {

    if (this.messageTextForm.invalid)
      return;
    this.sendMessage.emit(this.messageTextForm.value);

    this.messageTextForm.setValue('');
  }

}
