import { Component, ElementRef } from '@angular/core';
import { twMerge } from 'tailwind-merge';

@Component({
  standalone: true,
  selector: 'app-dialog-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class DialogCardComponent {
  defaultClasses: string = 'block p-6 bg-black2 border-solid border-[1.5px] border-grey border-opacity-20 rounded-lg'
  constructor(private hostElement: ElementRef) {
    hostElement.nativeElement.classList = twMerge(this.defaultClasses, hostElement.nativeElement.classList)
  }
}
