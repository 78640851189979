<div class="loader-wrap" *ngIf="loading | async">
  <div class="loader-el"></div>
</div>
<div class="new-wallet dialog__content">
  <div class="flex mb-5">
    <button class="dialog__close" type="button" (click)="closeClick()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="dialog__wrap">
    <h2 class="dialog__title mb-12">Start token generation</h2>
    <form [formGroup]="walletForm" (ngSubmit)="onSubmit()">
      <mat-error *ngIf="error | async"><span>{{error | async}}</span></mat-error>
      <div class="mt-auto">
        <app-btn class="md:w-full" type="submit" [disabled]="!!(error | async)">Continue</app-btn>
      </div>
    </form>
  </div>
</div>