import * as moment from 'moment';

import { HttpClient, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { AppHeadersService } from "./appHeaders.service";
import {environment} from "../../../environments/environment";

@Injectable()
export class ApiClientBase {
  protected authorization: string | undefined | null;
  apiUrl: string;

  constructor(protected http: HttpClient, private headers: AppHeadersService) {
    // private addDefaultParams?: (params: HttpParams) => HttpParams) {
    this.apiUrl = environment.supportChat;
  }

  public options<T>(path: string, paramsObject: Object = {}): Observable<T> {
    return this.http.options<T>(`${ this.apiUrl }${ path }`, this.createOptions(paramsObject));
  }

  public get<T>(path: string, paramsObject: Object = {}, isJson: boolean = false, isBlob: boolean = false): Observable<T> {
    return this.http.get<T>(`${ this.apiUrl }${ path }`, this.createOptions(paramsObject, isJson, isBlob));
  }

  public post<T>(path: string, body: Object = {}, paramsObject: Object = {}): Observable<T> {
    return this.http.post<T>(`${ this.apiUrl }${ path }`, body, this.createOptions(paramsObject));
  }

  public put<T>(path: string, body: Object = {}, paramsObject: Object = {}): Observable<T> {
    return this.http.put<T>(`${ this.apiUrl }${ path }`, body, this.createOptions(paramsObject));
  }

  public patch<T>(path: string, body: Object, paramsObject: Object = {}): Observable<T> {
    const request = [];
    for (const prop in body) {
      if (body.hasOwnProperty(prop)) {
        const upperCaseProperty = prop.charAt(0).toUpperCase() + prop.slice(1);
        // @ts-ignore
        request.push({op: 'replace', path: `/${ upperCaseProperty }`, value: body[prop]});
      }
    }
    return this.http.patch<T>(`${ this.apiUrl }${ path }`, request, this.createOptions(paramsObject));
  }

  public delete<T>(path: string, paramsObject: Object = {}): Observable<T> {
    return this.http.delete<T>(`${ this.apiUrl }${ path }`, this.createOptions(paramsObject));
  }

  public upload<T>(path: string, body: FormData, paramsObject: Object = {}): Observable<HttpEvent<T>> {
    const headers = this.createHeaders();
    let queryParams: HttpParams = new HttpParams();
    Object.keys(paramsObject).forEach(key => {
      // @ts-ignore
      if (paramsObject[key] != null) {
        // @ts-ignore
        queryParams = queryParams.append(key, paramsObject[key].toString());
      }
    });

    return this.http.request<T>('POST', `${ this.apiUrl }${ path }`, {
      params: queryParams,
      headers: headers,
      body: body,
      reportProgress: true,
      responseType: 'json',
      observe: 'events',
    });
  }

  private createHeaders(contentType?: string) {
    let header = new HttpHeaders(this.headers.values);
    if (contentType) {
      header = header.append('Content-Type', contentType);
    }
    if (this.authorization) {
      header = header.append('Authorization', 'Bearer ' + this.authorization);
    }
    return header;
  }

  private createOptions(paramsObject: Object, isJson: boolean = false, isBlob = false): Object {
    const headers = this.createHeaders('application/json');
    let queryParams: HttpParams = new HttpParams();
    if (isJson) {
      paramsObject = {obj: encodeURI(JSON.stringify(paramsObject))};
    }

    Object.keys(paramsObject).forEach(key => {
      // @ts-ignore
      if (paramsObject[key] != null) {
        // @ts-ignore
        if (paramsObject[key].constructor === Array) {
          // @ts-ignore
          paramsObject[key].forEach(element => {
            queryParams = queryParams.append(key, element ? element.toString() : null);
          });
        }
        else { // @ts-ignore
          if (moment.isMoment(paramsObject[key]) || paramsObject[key] instanceof Date) {
                    // @ts-ignore
            queryParams = queryParams.append(key, paramsObject[key].toISOString());
                  }
                  else {
                    // @ts-ignore
            queryParams = queryParams.append(key, paramsObject[key].toString());
                  }
        }
      }
    });

    return {
      // params: this.addDefaultParams ? this.addDefaultParams(queryParams) : queryParams,
      params: queryParams,
      headers: headers,
      observe: isBlob ? 'response' : 'body',
      responseType: isBlob ? 'blob' : 'json'
    };
  }
}
