<div class="relative overflow-hidden">
    <div class='absolute top-[-1100px] left-0 flex justify-center w-full h-[2600px] z-[-1] overflow-hidden'>
        <app-gradient-bg class="top-[200px] hue-rotate-[10deg]"></app-gradient-bg>
    </div>
    
    <div class="lg:hidden py-2 pt-4 px-8">
        <a routerLink="/" class="text-[45px] text-white font-extrabold">4Tokens</a>
    </div>

    <div *ngIf="useCaseId" class="pt-[131px] md:pt-[245px] pb-[86px] md:pb-[130px] relative">
        <app-container class="max-w-[790px] m-auto">
            <a routerLink="/" class="lg:hidden text-md flex items-center text-white absolute top-8">
                <img class="mr-2" src="../../../../assets/svg/icons/back_arrow.svg">
                Back to landing page
            </a>

            <!-- <h1 class="font-manrope font-extrabold mb-4 text-white text-5xl leading-8 xl:text-6xl">FAQ</h1> -->
            <div class="
                bg-blue2 text-white
                rounded-full 
                h-20 w-20 
                flex justify-center items-center 
                mb-6
                group-hover:bg-blue4 group-hover:text-black2
                transition-all duration-75">
                <svg-icon src={{getUseCase(useCaseId).img}}></svg-icon>
            </div>
            <h2 class="text-white font-extrabold font-manrope mb-8 text-4xl xl:text-5xl">{{getUseCase(useCaseId).title}}</h2>
            <div class="text-white text-md xl:text-xl" innerHTML={{getUseCase(useCaseId).content}}></div>
        </app-container>

        <app-container class="mt-28">
            <h1 class="font-manrope font-semibold text-white text-5xl text-center mb-12 md:mb-20">
                More <b class="text-blue4">Use Cases</b>
            </h1>
            <div class="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3">
                <a *ngFor="let item of getFilteredUseCases()"
                    [routerLink]="['/', item.id]"
                    class="block">
                    <app-card class="group h-full md:pr-4 hover:shadow-glow hover:border-blue4 transition-all duration-75">
                        <div class="
                            bg-blue2 text-white
                            rounded-full 
                            h-20 w-20 
                            flex justify-center items-center 
                            mb-6
                            group-hover:bg-blue4 group-hover:text-black2
                            transition-all duration-75">
                            <svg-icon src={{item.img}}></svg-icon>
                        </div>
                        <h3 class="text-white font-manrope font-extrabold text-2xl mb-2">{{item.title}}</h3>
                        <p class="text-grey text-md">{{item.descr}}</p>
                    </app-card>
                </a>
            </div>
        </app-container>
    </div>
</div>