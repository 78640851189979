import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';

@Component({
  selector: 'app-transaction-generation-success',
  templateUrl: './transaction-generation-success.component.html'
})

export class TransactionGenerationSuccessComponent {

  constructor(public snackBarRef: MatSnackBarRef<TransactionGenerationSuccessComponent>,
              @Inject(MAT_SNACK_BAR_DATA) public data: any) {
  }

  closeClick(): void {
    this.snackBarRef.dismiss();
  }

}
