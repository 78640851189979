<!--<div class="loader-wrap" *ngIf="(loading | async) || (msgSuccess | async) || (msgFailure | async)">-->

<div class="loader-wrap" *ngIf="(msgSuccess | async) || (msgFailure | async)">
  <!-- <div class="loader-wrap" *ngIf="true"> -->
<!--  <div class="loader-el" *ngIf="loading | async"></div>-->
  <div class="msgSuccess-el" *ngIf="msgSuccess | async"></div>
  <div class="msgFailure-el" *ngIf="msgFailure | async"></div>
</div>
<div class="relative overflow-hidden">
    <div class="lg:hidden py-2 pt-4 px-8">
        <a routerLink="/" class="text-[45px] text-white font-extrabold">4Tokens</a>
    </div>
    <div class="bg-black2 pt-[131px] md:pt-[245px] pb-[50px] md:pb-[50px] relative">
        <app-container>
            <a routerLink="/" class="lg:hidden text-md flex items-center text-white absolute top-8">
                <img class="mr-2" src="../../../../assets/svg/icons/back_arrow.svg">
                Back to landing page
            </a>

            <h1 class="font-manrope font-extrabold mb-4 text-white text-5xl leading-8 xl:text-6xl">Tokens list</h1>
            <div class="grid md:grid-cols-12">
                <p class="text-grey md:text-white text-lg xl:text-xl md:col-span-8">On this page you can see all discovered tokens with their statuses.</p>
            </div>

        </app-container>
    </div>

    <app-loader></app-loader>

    <app-container class="pt-[20px] pb-[100px]">
      <button type="button" class="hideFakeButton" (click)="showFake()">
        <span>{{(isShowFakeTokens) ? 'Hide fake tokens' : 'Show fake tokens'}}</span><img src="/assets/images/shevron.svg" >
      </button>
      <div class="filterSymbolForm">
        <input class="filterToken" oninput="this.value = this.value.toUpperCase()" (keyup)="applyFilter($event)" placeholder="Filter Token..." #input>
<!--          <button *ngIf="input.value" matSuffix mat-icon-button aria-label="Clear" (click)="input.value='';resetFilter()">-->
<!--            <mat-icon>close</mat-icon>-->
<!--          </button>-->
      </div>

      <div class="buttonsForm">
        <button type="button" class="typeTokenButton" (click)="clickTab(0)">Trust</button>
        <button type="button" class="typeTokenButton" (click)="clickTab(1)">Normal</button>
        <button *ngIf="isShowFakeTokens" type="button" class="typeTokenButton" (click)="clickTab(2)">Fake</button>
      </div>

      <div class="card-container">
        <div class="background-value background-value-mobile" [style.height]="(255 + dataTokens.length * 95).toString() + 'px'">
          <div class="ellipse_3"></div>
          <div class="ellipse_5"></div>
          <div class="ellipse_8"></div>
        </div>
        <div class="container-token-card">
          <div>
            <mat-card class="token-card token-card-mobile" [ngClass]="{'token-trust-card': (currentTab === 0), 'token-normal-card': (currentTab === 1), 'token-fake-card': (currentTab === 2)}">
              <mat-card-content class="matCardMobile">
                <p class="tokenStatus" *ngIf="currentTab === 0">Trust tokens:</p>
                <p class="tokenStatus" *ngIf="currentTab === 1">Normal tokens:</p>
                <p class="tokenStatus" *ngIf="currentTab === 2">Fake tokens:</p>

                <div *ngIf="resultsLength > 5" class="tokensPagesElementsMobile">

                  <span class="itemsPerPage itemsPerPageMobile">
                    Items per page:
                  </span>
                  <div class="dropDownPages dropDownPagesMobile">
                    <mat-select [(value)]="pageSize" class="pageSelector pageSelectorMobile">
                      <mat-option value="5" (click)="pageSizeChanged()">5</mat-option>
                      <mat-option *ngIf="resultsLength > 5" value="10" (click)="pageSizeChanged()">10</mat-option>
                      <mat-option *ngIf="resultsLength > 10" value="20" (click)="pageSizeChanged()">20</mat-option>
                      <mat-option *ngIf="resultsLength > 20" value="50" (click)="pageSizeChanged()">50</mat-option>
                    </mat-select>
                  </div>

                  <span class="currentPages currentPagesMobile">
                      {{(currentPage > 0 && resultsLength > 0) ? (currentPage - 1) * Number(pageSize) + 1 : 0}} – {{(currentPage > 0 && resultsLength > 0) ? ((currentPage * Number(pageSize) <= resultsLength) ? currentPage * Number(pageSize) : resultsLength) : 0}} of {{resultsLength}}
                  </span>
                  <div class="blockSeparateButtons blockSeparateButtonsMobile">
                    <button class="navigateButtons"
                            [disabled]="currentPage === 0 || resultsLength === 0 || currentPage < 2"
                            (click)="navFirstPage()">
                      <img *ngIf="currentPage !== 0 && resultsLength !== 0 && currentPage >= 2"
                           src="../../../../assets/images/list_first_default.svg"
                           onmouseover="this.src='../../../../assets/images/list_first.svg'"
                           onmouseout="this.src='../../../../assets/images/list_first_default.svg'">
                      <img *ngIf="currentPage === 0 || resultsLength === 0 || currentPage < 2" src="../../../../assets/images/list_first_disabled.svg">
                    </button>
                    <button class="navigateButtons"
                            [disabled]="currentPage === 0 || resultsLength === 0 || currentPage < 2"
                            (click)="navPreviousPage()">
                      <img *ngIf="currentPage !== 0 && resultsLength !== 0 && currentPage >= 2"
                           src="../../../../assets/images/list_prev_default.svg"
                           onmouseover="this.src='../../../../assets/images/list_prev.svg'"
                           onmouseout="this.src='../../../../assets/images/list_prev_default.svg'">
                      <img *ngIf="currentPage === 0 || resultsLength === 0 || currentPage < 2" src="../../../../assets/images/list_prev_disabled.svg">
                    </button>
                    <button class="navigateButtons"
                            [disabled]="currentPage === 0 || resultsLength === 0 || currentPage >= Math.ceil(resultsLength/Number(pageSize))"
                            (click)="navNextPage()">
                      <img *ngIf="currentPage !== 0 && resultsLength !== 0 && currentPage < Math.ceil(resultsLength/Number(pageSize))"
                           src="../../../../assets/images/list_next_default.svg"
                           onmouseover="this.src='../../../../assets/images/list_next.svg'"
                           onmouseout="this.src='../../../../assets/images/list_next_default.svg'">
                      <img *ngIf="currentPage === 0 || resultsLength === 0 || currentPage >= Math.ceil(resultsLength/Number(pageSize))" src="../../../../assets/images/list_next_disabled.svg">
                    </button>
                    <button class="navigateButtons"
                            [disabled]="currentPage === 0 || resultsLength === 0 || currentPage >= Math.ceil(resultsLength/Number(pageSize))"
                            (click)="navLastPage()">
                      <img *ngIf="currentPage !== 0 && resultsLength !== 0 && currentPage < Math.ceil(resultsLength/Number(pageSize))"
                           src="../../../../assets/images/list_last_default.svg"
                           onmouseover="this.src='../../../../assets/images/list_last.svg'"
                           onmouseout="this.src='../../../../assets/images/list_last_default.svg'">
                      <img *ngIf="currentPage === 0 || resultsLength === 0 || currentPage >= Math.ceil(resultsLength/Number(pageSize))" src="../../../../assets/images/list_last_disabled.svg">
                    </button>
                  </div>

                </div>


                <ul class="tokensList" *ngIf="dataTokens.length > 0">

                  <li class="tokenElement" *ngFor="let token of dataTokens" (click)="showToken(token)">
                    <img *ngIf="token.logo === undefined || token.logo === null || token.logo === ''" class="tokenLogo" src="../../../../assets/svg/icons/cil.svg">
                    <img *ngIf="token.logo !== undefined && token.logo !== null && token.logo !== ''" class="tokenLogo" [src]="token.logo! | protectedImage | async" onerror="this.src='../../../../assets/images/loader_image.gif'">

                    <div class="tokenDescription">
                      <span>
                        {{(token.ticker && token.ticker.length > 0) ? token.ticker.toUpperCase() : token.currency?.toUpperCase() }}
                        {{(token.description && token.description.length > 0) ? token.description : ''}}
                      </span>
                    </div>

                  </li>
                </ul>
                <div *ngIf="dataTokens.length === 0" class="text-no-items">
                  <span>
                    No items
                  </span>

                </div>
              </mat-card-content>
              <mat-card-actions *ngIf="resultsLength > 5" class="tokensPagesElements">

                <span class="itemsPerPage">
                  Items per page:
                </span>
                <div class="dropDownPages">
                  <mat-select [(value)]="pageSize" class="pageSelector">
                    <mat-option value="5" (click)="pageSizeChanged()">5</mat-option>
                    <mat-option *ngIf="resultsLength > 5" value="10" (click)="pageSizeChanged()">10</mat-option>
                    <mat-option *ngIf="resultsLength > 10" value="20" (click)="pageSizeChanged()">20</mat-option>
                    <mat-option *ngIf="resultsLength > 20" value="50" (click)="pageSizeChanged()">50</mat-option>
                  </mat-select>
                </div>

                <span class="currentPages">
                      {{(currentPage > 0 && resultsLength > 0) ? (currentPage - 1) * Number(pageSize) + 1 : 0}} – {{(currentPage > 0 && resultsLength > 0) ? ((currentPage * Number(pageSize) <= resultsLength) ? currentPage * Number(pageSize) : resultsLength) : 0}} of {{resultsLength}}
                </span>
                <div class="blockSeparateButtons">
                  <button class="navigateButtons"
                          [disabled]="currentPage === 0 || resultsLength === 0 || currentPage < 2"
                          (click)="navFirstPage()">
                    <img *ngIf="currentPage !== 0 && resultsLength !== 0 && currentPage >= 2"
                         src="../../../../assets/images/list_first_default.svg"
                         onmouseover="this.src='../../../../assets/images/list_first.svg'"
                         onmouseout="this.src='../../../../assets/images/list_first_default.svg'">
                    <img *ngIf="currentPage === 0 || resultsLength === 0 || currentPage < 2" src="../../../../assets/images/list_first_disabled.svg">
                  </button>
                  <button class="navigateButtons"
                          [disabled]="currentPage === 0 || resultsLength === 0 || currentPage < 2"
                          (click)="navPreviousPage()">
                    <img *ngIf="currentPage !== 0 && resultsLength !== 0 && currentPage >= 2"
                         src="../../../../assets/images/list_prev_default.svg"
                         onmouseover="this.src='../../../../assets/images/list_prev.svg'"
                         onmouseout="this.src='../../../../assets/images/list_prev_default.svg'">
                    <img *ngIf="currentPage === 0 || resultsLength === 0 || currentPage < 2" src="../../../../assets/images/list_prev_disabled.svg">
                  </button>
                  <button class="navigateButtons"
                          [disabled]="currentPage === 0 || resultsLength === 0 || currentPage >= Math.ceil(resultsLength/Number(pageSize))"
                          (click)="navNextPage()">
                    <img *ngIf="currentPage !== 0 && resultsLength !== 0 && currentPage < Math.ceil(resultsLength/Number(pageSize))"
                         src="../../../../assets/images/list_next_default.svg"
                         onmouseover="this.src='../../../../assets/images/list_next.svg'"
                         onmouseout="this.src='../../../../assets/images/list_next_default.svg'">
                    <img *ngIf="currentPage === 0 || resultsLength === 0 || currentPage >= Math.ceil(resultsLength/Number(pageSize))" src="../../../../assets/images/list_next_disabled.svg">
                  </button>
                  <button class="navigateButtons"
                          [disabled]="currentPage === 0 || resultsLength === 0 || currentPage >= Math.ceil(resultsLength/Number(pageSize))"
                          (click)="navLastPage()">
                    <img *ngIf="currentPage !== 0 && resultsLength !== 0 && currentPage < Math.ceil(resultsLength/Number(pageSize))"
                         src="../../../../assets/images/list_last_default.svg"
                         onmouseover="this.src='../../../../assets/images/list_last.svg'"
                         onmouseout="this.src='../../../../assets/images/list_last_default.svg'">
                    <img *ngIf="currentPage === 0 || resultsLength === 0 || currentPage >= Math.ceil(resultsLength/Number(pageSize))" src="../../../../assets/images/list_last_disabled.svg">
                  </button>
                </div>

              </mat-card-actions>
            </mat-card>
          </div>
        </div>

      </div>
    </app-container>

<!--    <div class='absolute bottom-[-1100px] lg:bottom-[-1800px] left-0 flex justify-center w-full h-[2600px] z-[-1] overflow-hidden'>-->
<!--        <app-gradient-bg class="top-[200px] hue-rotate-[10deg]"></app-gradient-bg>-->
<!--    </div>-->
</div>
