import { Transaction } from './lib/transaction';
import { CilWalletListUnspentItem, IWalletListUnspentQueryResult } from '../../components/SocialNetworkClient';
import { WalletDto } from '../Wallets/WalletDto';
const CIL_TX_FEE = 4000;

const TX_FEE = 4000; // money send fee per Kbyte
const CONTRACT_CREATION_FEE = 1e4; // contract creation
const CONTRACT_INVOCATION_FEE = 1e4; // contract invocation
const INTERNAL_TX_FEE = 300; // contract send moneys
const STORAGE_PER_BYTE_FEE = 12;

const _nFeeDeploy = CONTRACT_CREATION_FEE;
const _nFeeInvoke = CONTRACT_INVOCATION_FEE;
const _nFeePerInputOutput = TX_FEE * 0.12; //480
const _nFeePerInputNoSign = TX_FEE * 0.04; //160

const extraBytes = 144; //68 - tx, 8 - amount, 8 - receive, 20 - amountAddress, 20 - receiverAddress , overhead - 20
const bytesForInput = 41;

export class CilTransactionHelper {

  public static calculateTxFee(contractDataLength: number, nInputsCount: number, nOutputsCount: number,
                               bWithChange = true, bOneSignature = true): number {
    const txFee = bOneSignature ?
      _nFeePerInputNoSign * (nInputsCount) + _nFeePerInputOutput * (nOutputsCount + (bWithChange ? 1 : 0)) +
      (contractDataLength * STORAGE_PER_BYTE_FEE) + (extraBytes * STORAGE_PER_BYTE_FEE) + _nFeeInvoke :
    //   + (nInputsCount * bytesForInput * STORAGE_PER_BYTE_FEE):
      _nFeePerInputOutput * (nOutputsCount + nInputsCount + (bWithChange ? 1 : 0)) +
      (contractDataLength * STORAGE_PER_BYTE_FEE) + (extraBytes * STORAGE_PER_BYTE_FEE) + _nFeeInvoke;
    //   + (nInputsCount * bytesForInput * STORAGE_PER_BYTE_FEE);

    // CIL_TX_FEE
    const nEmptyTx = 6;
    // sweep - 1 получатель
    const nOutByteSize = 33 * (nOutputsCount + (bWithChange ? 1 : 0));
    const nInSize = nInputsCount * 39;
    // 1 ключ на все?sss
    const nSigSize = bOneSignature ? 67 : nInputsCount * 67;

    const nFee = parseInt(String(TX_FEE / 1024 * (nEmptyTx + nOutByteSize + nInSize + nSigSize + 2) + STORAGE_PER_BYTE_FEE * (contractDataLength + extraBytes)), 10) + 1 + _nFeeInvoke;

    return txFee;
  }

  public static UTXOCount(unspents: IWalletListUnspentQueryResult): number {
    let count = 0;
    for (const coins of unspents.items!) {
      if (!coins.amount) continue;
      count += 1;
    }
    return count;
  }

  //amount = 130000
  public static gatherInputsForContractCall(unspents: IWalletListUnspentQueryResult, amount: number, bBigFirst = false):
    { arrCoins: CilWalletListUnspentItem[]; gathered: number } {

    return this.gatherInputsForAmount(unspents, amount + _nFeeInvoke, bBigFirst);
  }

  private static gatherInputsForAmount(unspents: IWalletListUnspentQueryResult, amount: number, bBigFirst = false):
    {arrCoins: CilWalletListUnspentItem[]; gathered: number} {

    const arrCoins: CilWalletListUnspentItem[] = [];
    let gathered = 0;
    const arrUtxos = bBigFirst ? unspents?.items!.sort((a: any, b: any) => b.amount - a.amount) : unspents.items;
    for (const coins of arrUtxos!) {
      if (!coins.amount) continue;
      gathered += coins.amount;
      arrCoins.push(coins);
      if (gathered > Number(amount) + Number(_nFeePerInputOutput) * (Number(arrCoins.length) + 1)) {
        return {
          arrCoins,
          gathered
        };
      }

    }
    throw new Error('Not enough coins!');
  }


  static getCilSmartContractTran(wallet: WalletDto, contractAddress: string, contractConciliumId: number, amount: number,
                                 unspents: IWalletListUnspentQueryResult, method: string, arrArguments: any[]) {
    const contractCode = {
      method: method,
      arrArguments: arrArguments
    };

    let contractDataLength = 31; //скобки, запятые
    contractDataLength += method.length;
    for (const arg of arrArguments) {
      const argString = JSON.stringify(arg);
      contractDataLength += argString.length;
    }

    const tx = Transaction.invokeContract(
      contractAddress,
      contractCode,
      amount,
      wallet.address,
    );

    tx.conciliumId = contractConciliumId;

    //CALCULATE INPUTS

    let {arrCoins, gathered} = this.gatherInputsForContractCall(unspents, amount);
    //CALCULATE FEE
    let fee  = this.calculateTxFee(contractDataLength, arrCoins.length, 1);

    const utxoCount = this.UTXOCount(unspents);
    while ((gathered < amount + fee) && (arrCoins.length !== utxoCount))
    {
      const val = this.gatherInputsForContractCall(unspents, amount + fee);
      arrCoins = val.arrCoins;
      gathered = val.gathered;
      fee  = this.calculateTxFee(contractDataLength, arrCoins.length, 1);
    }

    for (const utxo of arrCoins) {
      tx.addInput(utxo.hash, utxo.nOut);
    }

    const amountToSend = gathered - fee - amount;
    if (amountToSend > 0) //!!!
      tx.addReceiver(amountToSend, wallet.address);

    // for (const i in arrCoins) {
    //   tx.claim(parseInt(i), wallet.getPrivateKey());
    // }

    // Only 1 sign
    tx.signForContract(wallet.getPrivateKey());

    return tx;
  }

  static getCilTran(wallet: WalletDto, addressTo: string, amount: number, unspents: IWalletListUnspentQueryResult) {
    // let nInputUsed = 0;
    // const arrPkToSign = [];

    const tx = new Transaction(undefined);

    const {arrCoins, gathered, fee} = this.getFee(amount, unspents);

    for (const input of arrCoins) {
      tx.addInput(input.hash, input.nOut);
      // arrPkToSign[nInputUsed++] = wallet.getPrivateKey();
    }

    tx.conciliumId = 1;
    tx.addReceiver(amount, addressTo);

    const change = gathered - amount - fee;
    if (change) {
      tx.addReceiver(change, wallet.address);
    }

    // подписываем
    // for (let idx = 0; idx < arrPkToSign.length; idx++) {
    //   tx.claim(idx, arrPkToSign[idx]);
    // }
    // Only 1 sign
    tx.signForContract(wallet.getPrivateKey());

    tx.verify();
    return tx;
  }

  static getFee(amount: number, unspents: IWalletListUnspentQueryResult, nOutputUsed: number = 1, bSingle: boolean = true) {
    const bBigFirst = amount >= 1000000;
    const arrCoins: CilWalletListUnspentItem[] = [];
    let gathered = 0;
    // @ts-ignore
    const arrUtxos = bBigFirst ? unspents.items.sort((a, b) => b.amount - a.amount) : unspents.items;

    // @ts-ignore
    for (const coins of arrUtxos) {
      if (!coins.amount) {
        continue;
      }
      gathered += coins.amount;
      arrCoins.push(coins);

      // CIL_TX_FEE
      const nEmptyTx = 6;
      // sweep - 1 получатель
      let nOutByteSize = 33 * nOutputUsed;
      const nInSize = arrCoins.length * 39;
      // 1 ключ на все?
      const nSigSize = bSingle ? 67 : arrCoins.length * 67;

      let nFee = parseInt(String(CIL_TX_FEE / 1024 * (nEmptyTx + nOutByteSize + nInSize + nSigSize + 2)), 10) + 1;

      if (gathered >= amount + nFee) {
        if (gathered === amount + nFee) { return {arrCoins, gathered, fee: nFee}; }
        // add +1 output
        nOutByteSize = 33 * (nOutputUsed + 1);
        nFee = parseInt(String(CIL_TX_FEE / 1024 * (nEmptyTx + nOutByteSize + nInSize + nSigSize + 2)), 10) + 1;

        if (gathered >= amount + nFee) { return {arrCoins, gathered, fee: nFee}; }
      }
    }
    throw new Error('Not enough coins!');
  }

}
