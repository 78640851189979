
<div class="dialog__content">
  <ng-container *ngIf="!(loading | async); else loadingTemplate">
    <h2 class="dialog__title mb-7">Transaction Completed</h2>
    <form class="dialog__wrap" [formGroup]="finishForm" (ngSubmit)="closeDialog()">
      <app-dialog-card class="mb-6">
        <div class="mb-8">
          <p class="text-grey mb-[10px]">Emission Account</p>
          <div class="flex items-center">
            <p class="text-white break-all">{{data.hashAddress}}</p>
            <a class="p-[10px] ml-4" type="button" target="_blank"
               [href]="explorerUri + '/#address/' + data.hashAddress" matTooltip="Emission account info" matTooltipPosition="below">
              <svg-icon svgClass="text-grey" src="./assets/svg/icons/copy.svg"></svg-icon>
            </a>
          </div>
        </div>
        <div>
          <p class="text-grey mb-[10px]">Contract Account</p>
          <div class="flex items-center">
            <p class="text-white break-all">Ux{{contractAddress}}</p>
            <a class="p-[10px] ml-4" type="button" target="_blank"
               [href]="explorerUri + '/#address/Ux' + contractAddress" matTooltip="Contract account info" matTooltipPosition="below">
              <svg-icon svgClass="text-grey" src="./assets/svg/icons/copy.svg"></svg-icon>
            </a>
          </div>
        </div>
      </app-dialog-card>

      <div class="grid grid-cols-2 gap-2 mb-6">
        <app-dialog-card class="col-span-2">
          <p class="text-grey">Token</p>
          <div class="flex items-center">
            <p class="text-white break-all">{{data.tiket}}</p>
            <a class="p-[10px] ml-4" type="button" target="_blank"
               [href]="explorerUri + '/#token/' + data.tiket" matTooltip="Token info" matTooltipPosition="right">
              <svg-icon svgClass="text-grey" src="./assets/svg/icons/copy.svg"></svg-icon>
            </a>
          </div>
        </app-dialog-card>
        <app-dialog-card>
          <p class="text-grey">Emission</p>
          <p class="text-white">{{data.emission.toLocaleString()}}</p>
        </app-dialog-card>
        <app-dialog-card>
          <p class="text-grey">Decimal </p>
          <p class="text-white">{{data.decimal}}</p>
        </app-dialog-card>
      </div>
      <ng-container *ngIf="balance | async as balance">
        <app-dialog-card>
          <p class="text-grey">Current Balance</p>
          <p class="text-white">{{balance.value | number:'1.0-5'}} UBX</p>
        </app-dialog-card>
      </ng-container>

      <!-- <mat-card>
        <mat-card-content>
          <p><span>Emission Address:</span> <a href="{{explorerUri}}/#address/{{data.hashAddress}}" target="_blank">{{data.hashAddress}}</a></p>
          <p><span>Contract Address:</span> <a href="{{explorerUri}}/#address/Ux{{contractAddress}}" target="_blank">Ux{{contractAddress}}</a></p>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-content>
          <p><span>Token:</span> <a href="{{explorerUri}}/#token/{{data.tiket}}" target="_blank">{{data.tiket}}</a></p>
          <p><span>Emission:</span> {{data.emission.toLocaleString()}}</p>
          <p><span>Decimal:</span> {{data.decimal}}</p>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-content>
          <ng-container *ngIf="balance | async as balance">
            <p><span>Current Balance:</span> {{balance.value | number:'1.0-5'}} UBX</p>
          </ng-container>
        </mat-card-content>
      </mat-card> -->

      <p *ngIf="data.messageType == 1" class="text-red text-xs mt-6">
        Your tokens are not ready yet. They will be generated shortly. We are facing a delay in transaction processing. Please wait...
      </p>

      <app-btn type="submit" class="mt-12 md:w-full">Finish</app-btn>
    </form>
  </ng-container>
</div>

<ng-template #loadingTemplate>
  <h2 class="dialog__title mb-[14px] mt-[104px] md:mt-[38px]">Your token is being developed</h2>
  <p class="text-grey text-center text-sm md:text-md">It might take a few minutes. This is the perfect time to take a coffee and relax. Don’t worry. We will let you know as soon as the process is finished.</p>

  <div class="loader-el loader-el--centered"></div>

  <p class="mt-auto text-grey text-center text-sm md:text-md mb-[104px] md:mb-6">Please don't close your browser until the transaction is completed</p>
</ng-template>
