import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent {

  messageForm: FormGroup;
  token: string | undefined;

  constructor(public dialogRef: MatDialogRef<MessageBoxComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {

    this.token = undefined;

    this.messageForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      telegram: new FormControl(''),
      message: new FormControl('', [Validators.required, Validators.minLength(10)])
    });
  }

  closeClick(): void {
    this.dialogRef.close();
  }

  Submit(form: NgForm) {
    if (this.messageForm.invalid || form.invalid) {
      console.log("Error! Invalid form data!")
      return;
    }
    const email = this.messageForm.value.email;
    const telegram = this.messageForm.value.telegram;
    const message = this.messageForm.value.message;
    this.messageForm.reset()
    this.dialogRef.close({email: email, telegram: telegram, message: message});
  }

  getEmailErrorMessage() {
    if (this.messageForm.get('email')?.hasError('required')) {
      return 'You must enter E-Mail';
    }
    return this.messageForm.get('email')?.hasError('email') ? 'Not a valid E-Mail' : '';
  }

  getMessageErrorMessage() {
    if (this.messageForm.get('message')?.hasError('required')) {
      return 'You must enter Message';
    }
    return this.messageForm.get('message')?.hasError('minlength') ? 'Message min length is 10' : '';
  }
}
