<button
    (click)="click"
    class="
        filling-btn
        relative
        min-w-[304px]
        w-full
        h-[70px] 
        rounded-full
        text-white 
        border-1
        pl-8
        py-[9px]
        pr-2
        flex
        items-center
        font-manrope
        font-extrabold
        overflow-hidden {{highlight ? 'bg-highlight-gradient shadow-btn-highlight' : ''}}">
    <div class="
        filling-btn__bg
        w-0
        absolute
        left-0
        top-0
        h-full"></div>
    <div class="relative">
        <ng-content></ng-content>
    </div>
    <div class="
        relative
        h-[52px] 
        w-[52px] 
        rounded-full 
        bg-white 
        ml-auto 
        flex 
        justify-center 
        items-center">
        <svg class="text-blue2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M10.0001 6.5L15.7693 12L10.0001 17.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </div>
</button>