import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {NewWalletComponent} from "./dialogs/new-wallet/new-wallet.component";
import {filter, flatMap} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {AccessToWalletComponent} from "./components/access.to.wallet/access-to-wallet/access-to-wallet.component";
import {WalletDto} from "./models/Wallets/WalletDto";
import {ConfirmWalletComponent} from "./dialogs/confirm-wallet/confirm-wallet.component";
import {NewTokenComponent} from "./dialogs/new-token/new-token.component";
import {SendTransactionComponent} from "./dialogs/send-transaction/send-transaction.component";
import {TransactionCompleteComponent} from "./dialogs/transaction-complete/transaction-complete.component";
import {WalletViewModel} from "./components/walletViewModel";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit{

  _state = 0;
  _currentWallet: WalletViewModel | null = null;
  _tokenData: any = null;
  _tokenStatus = 0;
  loading = new BehaviorSubject<boolean>(false);
  id: string;

  constructor(private router: Router,
              protected dialog: MatDialog) {
    this.id = this.create_UUID();
  }

  ngOnInit() {
  }

  create_UUID(): string {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  startGenerate() {
    this._state = 0;
    this._currentWallet = null;
    this._tokenData = null;
    this._tokenStatus = 0;
    this.showDialog();
  }

  showDialog() {
    switch (this._state) {
      case 0: // START SCREEN
        this.dialog.open(NewWalletComponent, {
          panelClass: ['dialog', 'dialog--start'],
          width: '566px',
          maxWidth: '100vw',
          disableClose: true,
          data: {
            id: this.id
          }
        }).afterClosed()
          .pipe(filter(wallet => !!wallet))
          .subscribe((wallet) => {
            if (wallet && (wallet instanceof WalletViewModel)) {
              this._currentWallet = <WalletViewModel>wallet;
              this._state += 1;
              this.showDialog();
            }
          });
        break;
      case 1: // SELECT WALLET
        if (this._currentWallet !== null) {
          this.dialog.open(AccessToWalletComponent, {
            panelClass: ['dialog'],
            width: '566px',
            maxWidth: '100vw',
            disableClose: true,
            data: {
              coinType: this._currentWallet.coinType,
              isImport: true
            }
          }).afterClosed()
            .pipe(filter(walletDto => !!walletDto))
            .subscribe((walletDto) => {
              if (walletDto && (walletDto instanceof WalletDto)) {
                this._currentWallet!.hashAddress = (<WalletDto>walletDto).getAddress();
                this._state += 1;
                this.showDialog();
              } else if (walletDto && (walletDto === true)) {
                this._state -= 1;
                this.showDialog();
              }
            });
        } else {
          //error - cannot be
          this._state = 0;
          this._currentWallet = null;
          this._tokenData = null;
          this._tokenStatus = 0;
        }
        break;
      case 2: // CONFIRM WALLET
        if (this._currentWallet !== null) {
          this.dialog.open(ConfirmWalletComponent, {
            panelClass: ['dialog'],
            width: '566px',
            maxWidth: '100vw',
            disableClose: true,
            data: {hashAddress: this._currentWallet.hashAddress}
          }).afterClosed()
            .pipe(filter(confirm => !!confirm))
            .subscribe((confirm) => {
              if (confirm) {
                this._state = (confirm === 1) ? (this._state + 1) : (this._state - 1);
                this.showDialog();
              }
            });
        } else {
          //error - cannot be
          this._state = 0;
          this._currentWallet = null;
          this._tokenData = null;
          this._tokenStatus = 0;
        }
        break;
      case 3: // ENTER TOKEN DATA
        if (this._currentWallet !== null) {
          this.dialog.open(NewTokenComponent, {
            panelClass: ['dialog'],
            width: '566px',
            maxWidth: '100vw',
            disableClose: true,
            data: {
              tokenData: this._tokenData,
              id: this.id}
          }).afterClosed()
            .pipe(filter(data => !!data))
            .subscribe((data) => {
              if (data) {
                this._tokenData = (!data.isPrev) ? data : null;
                this._state = (!data.isPrev) ? (this._state + 1) : (this._state - 1);
                this.showDialog();
              }
            });
        } else {
          //error - cannot be
          this._state = 0;
          this._currentWallet = null;
          this._tokenData = null;
          this._tokenStatus = 0;
        }
        break;
      case 4: // SEND TRANSACTION
        if ((this._currentWallet !== null) && (this._tokenData !== null)) {
          this.dialog.open(SendTransactionComponent, {
            panelClass: ['dialog'],
            width: '566px',
            maxWidth: '100vw',
            disableClose: true,
            data: {
              hashAddress: this._currentWallet.hashAddress,
              tiket: this._tokenData.data.tiket,
              name: this._tokenData.data.name,
              logo: this._tokenData.iconSrc,
              facebook: this._tokenData.data.facebook,
              twitter: this._tokenData.data.twitter,
              tags: this._tokenData.tags,
              emission: Number(this._tokenData.data.emission),
              decimal: Number(this._tokenData.decimal),
              description: this._tokenData.data.description,
              decimals: Number(this._tokenData.decimal),
              id: this.id
            }
          }).afterClosed()
            .pipe(filter(success => !!success))
            .subscribe((success) => {
              if (success) {
                this._tokenStatus = (!success.isPrev) ? <number>success : 0;
                this._state = (!success.isPrev) ? (this._state + 1) : (this._state - 1);
                this.showDialog();
              }
            });
        } else {
          //error - cannot be
          this._state = 0;
          this._currentWallet = null;
          this._tokenData = null;
          this._tokenStatus = 0;
        }
        break;
      case 5: // TRANSACTION COMPLETE
        if ((this._currentWallet !== null) && (this._tokenData !== null) && (this._tokenStatus !== 0)) {
          this.dialog.open(TransactionCompleteComponent, {
            panelClass: ['dialog'],
            width: '566px',
            maxWidth: '100vw',
            disableClose: true,
            data: {
              messageType: this._tokenStatus,
              hashAddress: this._currentWallet.hashAddress,
              tiket: this._tokenData.data.tiket,
              emission: Number(this._tokenData.data.emission),
              decimal: Number(this._tokenData.decimal),
              description: this._tokenData.data.description,
              id: this.id
            }
          }).afterClosed();
        } else {
          //error - cannot be
          this._state = 0;
          this._currentWallet = null;
          this._tokenData = null;
          this._tokenStatus = 0;
        }
        break;
      default:
        //error - cannot be
        this._state = 0;
        this._currentWallet = null;
        this._tokenData = null;
        this._tokenStatus = 0;
        break;
    }
  }
}



