import { Component, OnInit } from '@angular/core';
import { UseCasesService } from '../../services/useCases.service';

@Component({
  selector: 'app-use-cases-section',
  templateUrl: './use-cases-section.component.html'
})
export class UseCasesSectionComponent implements OnInit {
  useCasesData: {
    id: string,
    img: string, 
    title: string, 
    descr: string,
    content: string
  }[] = []

  constructor(private _service: UseCasesService) {}

  ngOnInit(): void {
    this.useCasesData = this._service.getUseCases()
  }
}
