import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {WalletViewModel} from "../../components/walletViewModel";
import {BehaviorSubject} from 'rxjs';
import {SocialNetworkClient} from "../../components/SocialNetworkClient";

@Component({
  selector: 'app-new-wallet',
  templateUrl: './new-wallet.component.html',
  styleUrls: ['./new-wallet.component.sass']
})
export class NewWalletComponent {

  loading = new BehaviorSubject<boolean>(true);
  error = new BehaviorSubject<string | null>('');
  walletForm: FormGroup;
  id: string;

  constructor(
    protected socialNetworkClient: SocialNetworkClient,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<NewWalletComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.loading.next(false);
      this.id = data.id;
      this.walletForm = this.formBuilder.group({});
  }

  onSubmit(): void {
    if (this.walletForm.invalid) {
      return;
    }

    this.socialNetworkClient.sendStart(this.id).subscribe(() => {
      // send start token generation signal to server
    },() => {
      console.log("Error! Cannot send start token generation signal to server!")
    });

    this.loading.next(true);
    const newWallet = new WalletViewModel();
    this.loading.next(false);
    this.dialogRef.close(newWallet);
  }

  closeClick(): void {
    this.dialogRef.close();
  }
}
