<div class="relative overflow-hidden">
    <div class="lg:hidden py-2 pt-4 px-8">
        <a routerLink="/" class="text-[45px] text-white font-extrabold">4Tokens</a>
    </div>
    <div class="bg-black2 pt-[131px] md:pt-[245px] pb-[86px] md:pb-[130px] relative">
        <app-container>
            <a routerLink="/" class="lg:hidden text-md flex items-center text-white absolute top-8">
                <img class="mr-2" src="../../../../assets/svg/icons/back_arrow.svg">
                Back to landing page
            </a>

            <h1 class="font-manrope font-extrabold mb-4 text-white text-5xl leading-8 xl:text-6xl">FAQ</h1>
            <div class="grid md:grid-cols-12">
                <p class="text-grey md:text-white text-lg xl:text-xl md:col-span-8">Got questions? Look no further! Our FAQ page has got you covered with answers to some of the most commonly asked questions. <br/>Check it out and see if we've already answered your question!</p>
            </div>
            
        </app-container>
    </div>
    <app-container class="pt-[150px] pb-[620px] xl:pt-[195px] xl:pb-[295px]">
        <app-accordion [data]="accordionData"></app-accordion>
    </app-container>

    <div class='absolute bottom-[-1100px] lg:bottom-[-1800px] left-0 flex justify-center w-full h-[2600px] z-[-1] overflow-hidden'>
        <app-gradient-bg class="top-[200px] hue-rotate-[10deg]"></app-gradient-bg>
    </div>
</div>
