import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  scrollDirection: null | 'down' | 'up' = null
  nav: {url: string, text: string}[] = [
    {text: 'Solution', url: 'solution'},
    {text: 'Ecosystem', url: 'ecosystem'},
    {text: 'Tokens list', url: 'tokens'},
    {text: 'How it Works', url: 'how-it-works'},
    {text: 'Use Cases', url: 'use-cases'},
    {text: 'Contacts', url: 'contacts'},
  ]

  constructor(private viewportScroller: ViewportScroller) {}

  public scrollToEl(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit(): void {
    let lastScrollY = window.pageYOffset

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset
      const direction = scrollY > lastScrollY ? 'down' : 'up'
      if (direction !== this.scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
        this.scrollDirection = direction
      }
      lastScrollY = scrollY > 0 ? scrollY : 0
    }
    window.addEventListener('scroll', updateScrollDirection)
  }
}
