import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-confirm-wallet',
  templateUrl: './confirm-wallet.component.html',
  styleUrls: ['./confirm-wallet.component.sass']
})
export class ConfirmWalletComponent {

  loading = new BehaviorSubject<boolean>(true);
  error = new BehaviorSubject<string | null>('');

  walletForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ConfirmWalletComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.walletForm = this.formBuilder.group({});

    this.loading.next(false);
  }

  onSubmit(): void {
    if (this.walletForm.invalid) {
      return;
    }
    this.dialogRef.close(1);
  }

  closeClick(isPrev: boolean = false): void {
    if (isPrev) {
      this.dialogRef.close(2);
    } else {
      this.dialogRef.close();
    }
  }

}
