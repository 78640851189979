import { Component, ElementRef } from '@angular/core';
import { twMerge } from 'tailwind-merge';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html'
})
export class CardComponent {
  defaultClasses: string = 'block p-8 md:p-12 bg-black2 border-solid border-[1.5px] border-grey border-opacity-20 rounded-2xl shadow-card'
  constructor(private hostElement: ElementRef) {
    hostElement.nativeElement.classList = twMerge(this.defaultClasses, hostElement.nativeElement.classList)
  }
}
