import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {BehaviorSubject, Subject} from 'rxjs';
import {ErrorStateMatcher} from "@angular/material/core";
import {
  SocialNetworkClient,
  WalletBalanceQueryResult
} from "../../components/SocialNetworkClient";
import {DestroyComponent} from "../../components/access.to.wallet/destroy-component";
import {environment} from "../../../../environments/environment";


@Component({
  selector: 'send-transaction-complete',
  templateUrl: './transaction-complete.component.html',
  styleUrls: ['./transaction-complete.component.sass']
})
export class TransactionCompleteComponent extends DestroyComponent implements OnInit  {

  loading = new BehaviorSubject<boolean>(true);
  error = new Subject<string | null>;
  // @ts-ignore
  balance = new BehaviorSubject<{value: number | undefined} | null>(null);
  contractAddress = environment.cilTokenContractAddress;
  explorerUri = environment.explorerUri;
  id: string;
  finishForm = this.formBuilder.group({
  }, {});

  constructor(protected socialNetworkClient: SocialNetworkClient,
              protected formBuilder: FormBuilder,
              protected dialog: MatDialog,
              public dialogRef: MatDialogRef<TransactionCompleteComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    super();
    this.id = data.id;

    this.socialNetworkClient.getBalance(data.hashAddress, this.id)
      .subscribe((data: WalletBalanceQueryResult) => {
        this.balance.next({ value: data.balance });
      });
    this.loading.next(false);
  }

  ngOnInit() {
  }

  closeClick(): void {
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close(true);
  }
}
