import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, Validators, FormGroup, FormControl, ValidatorFn, ValidationErrors, AbstractControl} from '@angular/forms';
import {BehaviorSubject, timer} from 'rxjs';
import {SocialNetworkClient} from "../../components/SocialNetworkClient";
import {environment} from "../../../../environments/environment";
import {MatChipInputEvent} from "@angular/material/chips";

@Component({
  selector: 'app-new-token',
  templateUrl: './new-token.component.html',
  styleUrls: ['./new-token.component.sass']
})
export class NewTokenComponent {

  loading = new BehaviorSubject<boolean>(true);
  error = new BehaviorSubject<string | null>('');
  walletForm: FormGroup;
  isChecked = false;
  isCheckSuccess = false;
  prevTokenValue = '';
  decimal = 0;
  id: string;
  tags: string[] = [];
  iconName = '';
  iconSrc = '';
  iconError: string | null = null;
  imgURL: any;


  constructor(
    private formBuilder: FormBuilder,
    protected socialNetworkClient: SocialNetworkClient,
    public dialogRef: MatDialogRef<NewTokenComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.id = data.id;
      this.walletForm = this.formBuilder.group({
        tiket: new FormControl((data.tokenData) ? (data.tokenData.data.tiket) : '', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern('[A-Z0-9_-]*'), this.customValidator()]),
        name: new FormControl((data.tokenData) ? (data.tokenData.data.name) : '', [Validators.required, Validators.minLength(4), Validators.pattern('[a-zA-Z0-9_ ,.-]*')]),
        emission: new FormControl((data.tokenData) ? (data.tokenData.data.emission) :'', [Validators.required, Validators.minLength(1), Validators.maxLength(9), Validators.min(1), Validators.max(100000000), Validators.pattern('[0-9]*')]),
        description: new FormControl((data.tokenData) ? (data.tokenData.data.description) :''),
        facebook: new FormControl((data.tokenData) ? (data.tokenData.data.facebook) :''),
        twitter: new FormControl((data.tokenData) ? (data.tokenData.data.twitter) :'')
      });
    this.loading.next(false);

    if (data.tokenData) {
      this.decimal = Number(data.tokenData.decimal);
      this.checkToken();
    }

    if (data.tokenData) {
      this.tags = data.tokenData.tags;
    }

    if (data.tokenData) {
      this.iconName = data.tokenData.iconName;
      this.iconSrc = data.tokenData.iconSrc;
    }

  }

  private customValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === null) {
        return null;
      }
      return (!this.isChecked || (this.isChecked && !this.isCheckSuccess)) ? {'check': 'error'} : null;
    };
  }

  onSubmit(): void {
    if (this.walletForm.invalid || !this.isChecked || (this.isChecked && !this.isCheckSuccess)) {
      return;
    }
    this.dialogRef.close({data: this.walletForm.value,
                                    decimal: this.decimal,
                                    tags: this.tags,
                                    iconName: this.iconName,
                                    iconSrc: this.iconSrc});
  }

  closeClick(isPrev: boolean = false): void {
    if (isPrev) {
      this.dialogRef.close({isPrev: true});
    } else {
      this.dialogRef.close();
    }
  }


  getTiketErrorMessage() {
    if (this.walletForm.get('tiket')?.hasError('required')) {
      return 'You must enter ticker';
    }
    if (this.walletForm.get('tiket')?.hasError('pattern')) {
      return 'Only letters and numbers';
    }
    if (this.walletForm.get('tiket')?.hasError('minlength')) {
      return 'The ticker is 4 symbols long';
    }
    if (this.walletForm.get('tiket')?.hasError('maxLength')) {
      return 'The ticker is 4 symbols long';
    }
    if (this.walletForm.get('tiket')?.invalid && this.isChecked && this.isCheckSuccess) {
      this.walletForm.get('tiket')?.setErrors({'check': null});
      this.walletForm.get('tiket')?.updateValueAndValidity();
    }
    return !this.isChecked ? 'Check in progress...' : !this.isCheckSuccess ? 'The ticker is busy' : '';
  }

  getNameErrorMessage() {
    if (this.walletForm.get('name')?.hasError('required')) {
      return 'You must enter name';
    }
    if (this.walletForm.get('name')?.hasError('pattern')) {
      return 'Only letters and numbers';
    }
    if (this.walletForm.get('name')?.hasError('minlength')) {
      return 'Min length is 4 symbols';
    }

    return this.walletForm.get('name')?.invalid ? 'Invalid data for Name' : '';
  }

  getEmissionErrorMessage() {
    if (this.walletForm.get('emission')?.hasError('required')) {
      return 'Please enter an Emission';
    }
    if (this.walletForm.get('emission')?.hasError('min')) {
      return 'Incorrect MIN value for Emission';
    }
    if (this.walletForm.get('emission')?.hasError('max')) {
      return 'Incorrect MAX value for Emission';
    }
    if (this.walletForm.get('emission')?.hasError('minLength')) {
      return 'Incorrect MIN length for Emission';
    }
    if (this.walletForm.get('emission')?.hasError('maxLength')) {
      return 'Incorrect MAX length for Emission';
    }

    return this.walletForm.get('emission')?.invalid ? 'Incorrect integer number for Emission' : '';
  }

  getDescriptionErrorMessage() {
  }

  checkToken() {
    this.isChecked = false;
    this.isCheckSuccess = false;
    this.loading.next(true);

    // INSERT COMPARE TOKEN IS BUSY OR NOT
    const tiket = <string> this.walletForm.get('tiket')?.value;
    let isCheck = false;

    this.socialNetworkClient.getCilTokenStatus(tiket, this.id)
      .subscribe({
        next: (result) => {
          if (result !== undefined && result !== null) {
            if (result.isCreated !== undefined && result.isCreated !== null) {
              isCheck = true;
            }
          }
          if (isCheck) {
            this.isChecked = true;
            this.isCheckSuccess = !result.isCreated;
          } else {
            console.log('error! cant check token! incorrect data from server:', result);
          }
          this.loading.next(false);
          this.walletForm.get('tiket')?.updateValueAndValidity();
        },
        error: (err) => {
          console.log('error', err);
          this.loading.next(false);
        }
      });

  }

  tiketChange() {
    if (this.prevTokenValue !== this.walletForm.get('tiket')?.value)
    {
      this.prevTokenValue = this.walletForm.get('tiket')?.value;
      this.isChecked = false;
      this.isCheckSuccess = false;
      if (!this.walletForm.get('tiket')?.invalid ||
        (this.walletForm.get('tiket')?.invalid && !this.walletForm.get('tiket')?.hasError('required') &&
        !this.walletForm.get('tiket')?.hasError('pattern') && !this.walletForm.get('tiket')?.hasError('minlength') &&
        !this.walletForm.get('tiket')?.hasError('maxLength'))) {

        this.walletForm.get('tiket')?.markAsTouched();
        this.walletForm.get('tiket')?.updateValueAndValidity();
        this.checkToken();
      }
    }
  }

  resetTiket() {
    this.walletForm.get('tiket')?.setValue('');
  }

  decimalInc() {
    if (this.decimal <= 5) {
      this.decimal += 1;
    }
  }

  decimalDec() {
    if (this.decimal >= 1) {
      this.decimal -= 1;
    }
  }

  decimalFilter($event: KeyboardEvent) {
    if ($event.key === '.' || $event.key === ',' || $event.key === 'e' || $event.key === 'E' || $event.key === '-' || $event.key === '+') {
      $event.preventDefault();
    }
  }

  changeEmission() {
    try {
      this.walletForm.get('emission')?.setValue(Number(this.walletForm.get('emission')?.value).toString());
    } catch {}
  }

  removeTag(keyword: string) {
    const index = this.tags.indexOf(keyword);
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our keyword
    if (value) {
      this.tags.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  _handleReaderLoaded(readerEvt: any) {
    var binaryString = readerEvt.target.result;
    this.iconSrc = 'data:image/svg+xml;base64,' + btoa(binaryString);
    // console.log(this.iconSrc);
  }

  svgInputChange(fileInputEvent: any) {
    if (fileInputEvent) {
      console.log(fileInputEvent.target.files[0]);
      if (fileInputEvent.target.files[0].type === 'image/svg+xml') {

        if (fileInputEvent.target.files[0].size <= 819200) { //800KB

          this.iconName = fileInputEvent.target.files[0].name;
          // console.log(fileInputEvent.target.files[0]);

          let reader = new FileReader();
          reader.onload =this._handleReaderLoaded.bind(this);
          reader.readAsBinaryString(fileInputEvent.target.files[0]);

          let reader2 = new FileReader();
          reader2.readAsDataURL(fileInputEvent.target.files[0]);
          reader2.onload = (_event) => {
            this.imgURL = reader2.result;
          }
          this.iconError = null;
        }
        else {
          //Error - incorrect size
          this.imgURL = null;
          this.iconName = '';
          this.iconSrc = '';
          this.iconError = "Incorrect file size!"
          console.log("Incorrect file size!");
        }

      } else {
        //Error - incorrect type
        this.imgURL = null;
        this.iconName = '';
        this.iconSrc = '';
        this.iconError = "Incorrect file type selected!"
        console.log("Incorrect file type selected!");
      }
    }

  }

}
