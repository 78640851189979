import { Component, ElementRef } from '@angular/core';
import { twMerge } from 'tailwind-merge';

@Component({
  standalone: true,
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
})

export class ContainerComponent {
  defaultClasses: string = 'block px-6 w-full max-w-full xl:w-[1100px] xl:mx-auto'
  constructor(private hostElement: ElementRef) {
    hostElement.nativeElement.classList = twMerge(this.defaultClasses, hostElement.nativeElement.classList)
  }
}
