<div  #parallaxScene>
    <div class="relative w-full md:pb-[133px]">
        <div class="absolute top-0 left-0 flex justify-end w-full h-[2442px] overflow-hidden">
            <svg class="
                min-w-[1728px]
                rotate-12 xl:rotate-0
                translate-y-[-1150px] xl:translate-y-0
                origin-bottom" 
                width="1728" height="2342" viewBox="0 0 1728 2342" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.2" d="M2599.34 1094.09C2502.79 1381.3 2302.89 1622.34 2038.51 1770.37C1774.13 1918.4 1464.16 1962.84 1168.84 1895.05C873.519 1827.27 613.952 1652.1 440.592 1403.59C267.232 1155.08 192.464 850.995 230.822 550.432L232.033 550.587C193.714 850.849 268.407 1154.63 441.594 1402.89C614.78 1651.15 874.087 1826.14 1169.11 1893.86C1464.14 1961.58 1773.8 1917.19 2037.92 1769.3C2302.03 1621.42 2501.73 1380.62 2598.19 1093.7L2599.34 1094.09Z" fill="#EFF1F9"/>
                <path opacity="0.2" d="M2549.11 1077.2C2456.74 1351.94 2265.52 1582.52 2012.62 1724.12C1759.72 1865.73 1463.2 1908.24 1180.7 1843.4C898.194 1778.55 649.894 1610.98 484.06 1373.27C318.225 1135.55 246.702 844.658 283.394 557.142L284.553 557.29C247.898 844.518 319.348 1135.12 485.017 1372.6C650.686 1610.08 898.738 1777.48 1180.96 1842.26C1463.18 1907.04 1759.4 1864.57 2012.05 1723.11C2264.7 1581.64 2455.73 1351.29 2548 1076.83L2549.11 1077.2Z" fill="#EFF1F9"/>
                <path opacity="0.2" d="M2496.97 1059.68C2408.96 1321.48 2226.74 1541.2 1985.75 1676.13C1744.76 1811.07 1462.2 1851.58 1193 1789.79C923.801 1728 687.194 1568.32 529.168 1341.8C371.142 1115.27 302.987 838.081 337.952 564.104L339.056 564.245C304.126 837.948 372.213 1114.86 530.081 1341.16C687.948 1567.46 924.319 1726.98 1193.25 1788.71C1462.18 1850.43 1744.45 1809.96 1985.21 1675.16C2225.96 1540.36 2407.99 1320.86 2495.92 1059.32L2496.97 1059.68Z" fill="#EFF1F9"/>
            </svg>
        </div>
        <div class='absolute top-0 left-0 flex justify-center w-full h-[1600px] z-0 overflow-hidden'>
            <div data-depth="0.00" class="hidden"></div>
            <app-rectangles-bottom></app-rectangles-bottom>
            <app-gradient-bg class="top-[-200px]"></app-gradient-bg>
            <app-rectangles-top></app-rectangles-top>
        </div>
        <app-container>
            <div class="relative pt-[70px] md:pt-[150px] xl:pt-[322px]">
                <svg class="w-[200px] mr-auto mb-8 md:ml-auto md:mb-4 xl:w-[192px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 201 35" fill="none">
                    <path d="M0.5 28.5319V21.0768L14.6279 0.920382H24.522V20.7086H28.0655V28.5319H24.522V34.0542H15.4102V28.5319H0.5ZM16.1005 11.0906L9.74985 20.7086H16.1005V11.0906Z" fill="#5F14FE"/>
                    <path d="M56.9842 1.61067V8.78965H48.3326V34.0542H39.3128V8.78965H30.7533V1.61067H56.9842Z" fill="#EFF1F9"/>
                    <path d="M72.3323 34.3763C69.7552 34.3763 67.4389 33.8394 65.3834 32.7656C63.3586 31.6918 61.7632 30.1579 60.5974 28.1637C59.4316 26.1695 58.8487 23.8226 58.8487 21.1228C58.8487 18.4537 59.4316 16.122 60.5974 14.1279C61.7939 12.1337 63.4046 10.5997 65.4294 9.52596C67.4849 8.45218 69.8012 7.91529 72.3783 7.91529C74.9554 7.91529 77.2563 8.45218 79.2812 9.52596C81.3367 10.5997 82.9474 12.1337 84.1132 14.1279C85.3097 16.122 85.9079 18.4537 85.9079 21.1228C85.9079 23.7919 85.3097 26.1389 84.1132 28.1637C82.9474 30.1579 81.3367 31.6918 79.2812 32.7656C77.2256 33.8394 74.9094 34.3763 72.3323 34.3763ZM72.3323 26.553C73.5901 26.553 74.6332 26.0929 75.4616 25.1725C76.3206 24.2214 76.7501 22.8715 76.7501 21.1228C76.7501 19.3741 76.3206 18.0395 75.4616 17.1191C74.6332 16.1987 73.6055 15.7385 72.3783 15.7385C71.1511 15.7385 70.1234 16.1987 69.295 17.1191C68.4667 18.0395 68.0525 19.3741 68.0525 21.1228C68.0525 22.9022 68.4513 24.2521 69.249 25.1725C70.0467 26.0929 71.0744 26.553 72.3323 26.553Z" fill="#EFF1F9"/>
                    <path d="M105.73 34.0542L98.5974 23.4698V34.0542H89.5776V0H98.5974V18.3616L105.73 8.23742H116.545L106.375 21.2148L116.729 34.0542H105.73Z" fill="#EFF1F9"/>
                    <path d="M144.039 20.8467C144.039 21.5523 143.993 22.2579 143.901 22.9636H126.828C126.92 24.3748 127.304 25.4332 127.979 26.1389C128.684 26.8138 129.574 27.1513 130.648 27.1513C132.151 27.1513 133.225 26.4763 133.869 25.1265H143.487C143.088 26.9059 142.306 28.5012 141.14 29.9124C140.005 31.293 138.563 32.3821 136.814 33.1798C135.066 33.9775 133.133 34.3763 131.016 34.3763C128.47 34.3763 126.199 33.8394 124.205 32.7656C122.242 31.6918 120.692 30.1579 119.557 28.1637C118.453 26.1695 117.901 23.8226 117.901 21.1228C117.901 18.423 118.453 16.0914 119.557 14.1279C120.662 12.1337 122.196 10.5997 124.159 9.52596C126.153 8.45218 128.439 7.91529 131.016 7.91529C133.562 7.91529 135.817 8.43684 137.781 9.47994C139.744 10.523 141.278 12.0263 142.383 13.9898C143.487 15.9226 144.039 18.2082 144.039 20.8467ZM134.836 18.5917C134.836 17.4873 134.467 16.6283 133.731 16.0147C132.995 15.3704 132.074 15.0483 130.97 15.0483C129.866 15.0483 128.961 15.3551 128.255 15.9686C127.549 16.5515 127.089 17.4259 126.874 18.5917H134.836Z" fill="#EFF1F9"/>
                    <path d="M164.62 8.00733C167.596 8.00733 169.958 9.00441 171.707 10.9986C173.455 12.9621 174.33 15.6312 174.33 19.0059V34.0542H165.31V20.2024C165.31 18.7298 164.927 17.5793 164.16 16.751C163.393 15.8919 162.365 15.4624 161.076 15.4624C159.726 15.4624 158.668 15.8919 157.901 16.751C157.134 17.5793 156.75 18.7298 156.75 20.2024V34.0542H147.731V8.23742H156.75V11.919C157.548 10.7531 158.622 9.81741 159.972 9.11179C161.322 8.37548 162.871 8.00733 164.62 8.00733Z" fill="#EFF1F9"/>
                    <path d="M190.278 34.3763C187.946 34.3763 185.86 33.9928 184.019 33.2258C182.209 32.4282 180.767 31.3544 179.693 30.0045C178.65 28.6239 178.067 27.0746 177.944 25.3565H186.688C186.811 26.1849 187.194 26.8292 187.839 27.2894C188.483 27.7495 189.28 27.9796 190.232 27.9796C190.968 27.9796 191.551 27.8262 191.98 27.5194C192.41 27.2127 192.625 26.8138 192.625 26.3229C192.625 25.6787 192.272 25.2031 191.566 24.8964C190.86 24.5896 189.695 24.2521 188.069 23.8839C186.228 23.5158 184.694 23.1016 183.467 22.6414C182.24 22.1812 181.166 21.4296 180.245 20.3865C179.356 19.3434 178.911 17.9321 178.911 16.1527C178.911 14.6187 179.325 13.2382 180.153 12.011C180.982 10.7531 182.194 9.75606 183.789 9.01975C185.415 8.28345 187.363 7.91529 189.633 7.91529C193.008 7.91529 195.662 8.74364 197.595 10.4003C199.527 12.057 200.647 14.2353 200.954 16.935H192.809C192.655 16.1067 192.287 15.4778 191.704 15.0483C191.152 14.5881 190.4 14.358 189.449 14.358C188.713 14.358 188.145 14.496 187.747 14.7721C187.378 15.0483 187.194 15.4318 187.194 15.9226C187.194 16.5362 187.547 17.0117 188.253 17.3492C188.958 17.656 190.093 17.9781 191.658 18.3156C193.53 18.7144 195.079 19.1593 196.306 19.6502C197.564 20.141 198.653 20.9387 199.573 22.0432C200.524 23.1169 201 24.5896 201 26.461C201 27.9643 200.555 29.3142 199.665 30.5107C198.806 31.7072 197.564 32.6583 195.938 33.3639C194.343 34.0388 192.456 34.3763 190.278 34.3763Z" fill="#EFF1F9"/>
                </svg>
                <h1 class="drop-shadow font-manrope font-extrabold mb-6 text-white text-5xl leading-8 xl:text-6xl md:text-center">Next-gen tokenization platform</h1>
                
                <p class="text-white text-md mb-[42px] xl:mb-[142px] xl:text-xl md:text-center">No code token issuance and trading inside a single ecosystem. <br/>The cheapest monetization tool for creative professionals.</p>
    
                <div class='flex flex-col justify-center md:flex-row'>
                    <a [routerLink]="['./']" [fragment]="'how-it-works'" class="block mb-8 md:mb-0 md:mr-8"><app-filling-btn>How does it work?</app-filling-btn></a>
                    <app-home-page>
                        <app-filling-btn [highlight]="true">Issue your token!</app-filling-btn>
                    </app-home-page>
                </div>
            </div>
        </app-container>
    </div>
</div>
