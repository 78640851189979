<!-- <div class="registration-modal modal">
<button class="close" type="button" (click)="closeClick()">
  <mat-icon>close</mat-icon>
</button>
  <img src="../../../../../assets/svg/icons/warning.svg"/>
<h2>Sign transaction error</h2><span class="error-subhead">{{data.message}}</span>
<div class="buttons">
  <button class="button stroke key-button" type="button" (click)="dialogRef.close()">Close</button>
</div>
</div> -->
<p (click)="closeClick()"><b>Error! </b>{{data.message}}</p>
