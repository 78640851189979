<button 
    (click)="!disabled && onClick && onClick.emit()"
    [type]="type"
    [disabled]="disabled" 
    [ngClass]="{
        'w-full rounded-lg text-md leading-6 font-manrope font-extrabold p-[10px] flex justify-center items-center': true,
        'bg-blue2 hover:bg-blue3 active:bg-blue1 text-white': !disabled,
        'bg-grey text-black2 pointer-events-none': disabled,
        'bg-highlight-gradient': gradient
    }">
    <ng-content></ng-content>
    <svg [ngClass]="{'text-white': !disabled, 'text-black2': disabled}" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M10.0001 6.5L15.7693 12L10.0001 17.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</button>
