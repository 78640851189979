<div class="loader-wrap" *ngIf="loading | async">
<!-- <div class="loader-wrap" *ngIf="true"> -->
  <div class="loader-el"></div>
</div>
<div class="dialog__content">
  <div class="flex mb-5">
    <button class="dialog__close" type="button" (click)="closeClick()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <form class="dialog__wrap" [formGroup]="walletForm" (ngSubmit)="onSubmit()">
    <h1 class="dialog__step">
      <button class="dialog__prevStep" type="button" (click)="closeClick(true)">
        <img src="./assets/svg/icons/arrow.svg" alt="<">
      </button>
      <p>Step 5</p>
      <button class="dialog__nextStep" type="submit" [ngClass]="{'disabled' : walletForm.invalid || !isChecked || (isChecked && !isCheckSuccess)}" [disabled]="walletForm.invalid || !isChecked || (isChecked && !isCheckSuccess)">
        <img src="./assets/svg/icons/arrow.svg" alt=">">
      </button>
    </h1>
    <h2 class="dialog__title mb-8">Create your token</h2>

    <p class="form-field__caption">Ticker</p>
    <mat-form-field appearance="outline" class="form-field check-when-valid">
      <input id="tiket" formControlName="tiket" matInput required minlength="4" maxlength="4" (keyup)="tiketChange()" oninput="this.value = this.value.toUpperCase()">
      <button
        class="mat-mdc-form-field-clear"
        *ngIf="walletForm.get('tiket')?.touched && walletForm.get('tiket')?.value !== '' && (walletForm.get('tiket')?.invalid || (!walletForm.get('tiket')?.invalid && !isChecked) || (!walletForm.get('tiket')?.invalid && isChecked && !isCheckSuccess))"
        (click)="resetTiket()">
        <svg-icon src="./assets/svg/icons/error.svg"></svg-icon>
      </button>

      <mat-error *ngIf="walletForm.get('tiket')?.touched && walletForm.get('tiket')?.value !== '' && (walletForm.get('tiket')?.invalid || (!walletForm.get('tiket')?.invalid && !isChecked) || (!walletForm.get('tiket')?.invalid && isChecked && !isCheckSuccess))">{{getTiketErrorMessage()}}</mat-error>
    </mat-form-field>

    <p class="form-field__caption">Name</p>
    <mat-form-field appearance="outline" class="form-field">
      <input type="text" placeholder="Enter Name" id="name" formControlName="name" matInput required minlength="4">
      <mat-error *ngIf="walletForm.get('name')?.touched && walletForm.get('name')?.invalid">{{getNameErrorMessage()}}</mat-error>
    </mat-form-field>

    <p class="form-field__caption">Emission Volume</p>
    <mat-form-field appearance="outline" class="form-field check-when-valid">
      <input type="number" placeholder="Enter Emission volume (1-10^8)" id="emission" formControlName="emission" matInput required (keydown)="decimalFilter($event)" (change)="changeEmission()">
      <mat-error *ngIf="walletForm.get('emission')?.touched && walletForm.get('emission')?.invalid">{{getEmissionErrorMessage()}}</mat-error>
    </mat-form-field>

    <p class="form-field__caption">Select Decimal</p>
    <div class="number-field">
      <button class="number-field__btn" type="button" (click)="decimalDec()" [disabled]="decimal <= 0">
        <svg-icon src="./assets/svg/icons/minus.svg"></svg-icon>
      </button>
      <mat-form-field appearance="outline" class="form-field">
        <input class="text-center" type="text" matInput required id="decimal" readonly [(ngModel)]="decimal" [ngModelOptions]="{standalone: true}">
      </mat-form-field>
      <button class="number-field__btn" type="button" (click)="decimalInc()" [disabled]="decimal >= 6">
        <svg-icon src="./assets/svg/icons/plus.svg"></svg-icon>
      </button>
    </div>

    <p class="form-field__caption">Description (optional)</p>
    <mat-form-field appearance="outline" class="form-field">
      <textarea id="description" formControlName="description" matInput></textarea>
      <mat-error *ngIf="walletForm.get('description')?.touched && walletForm.get('description')?.invalid">{{getDescriptionErrorMessage()}}</mat-error>
    </mat-form-field>

    <p class="form-field__caption">Icon SVG 32x32 max size 800 KB (optional)</p>
    <div  *ngIf="imgURL">
      <p></p>
      <img [src]="imgURL" height="32" width="32" border="1">
      <p class="form-field__caption">{{iconName}}</p>
    </div>
    <input  #svgInput hidden="true" type="file" onclick="this.value=null" (change)="svgInputChange($event)" accept=".svg"/>
    <button type="button" mat-flat-button color="primary" (click)="svgInput.click()">Choose icon (svg)...</button>
    <p class="form-field__caption"><span *ngIf="iconError" style="color:red">{{iconError}}</span></p>

    <p class="form-field__caption">Facebook (optional)</p>
    <mat-form-field appearance="outline" class="form-field">
      <input type="text" placeholder="Enter Facebook account" id="facebook" formControlName="facebook" matInput>
    </mat-form-field>

    <p class="form-field__caption">Twitter (optional)</p>
    <mat-form-field appearance="outline" class="form-field">
      <input type="text" placeholder="Enter Twitter account" id="twitter" formControlName="twitter" matInput>
    </mat-form-field>

    <p class="form-field__caption">Tags (optional)</p>
    <mat-form-field class="example-form-field">
      <mat-chip-grid #chipGrid aria-label="Enter tags">
        <mat-chip-row *ngFor="let tag of tags" (removed)="removeTag(tag)">
          {{tag}}
          <button matChipRemove aria-label="'remove ' + tag">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </mat-chip-grid>
      <input placeholder="New tag..."
             [matChipInputFor]="chipGrid"
             (matChipInputTokenEnd)="addTag($event)"/>
    </mat-form-field>


    <!-- <button class="stroke button" type="submit" [ngClass]="{disabled: walletForm.invalid || !isChecked || (isChecked && !isCheckSuccess)}">Continue</button> -->
    <app-btn class="mt-auto md:w-full" type="submit" [disabled]="walletForm.invalid || !isChecked || (isChecked && !isCheckSuccess)">Continue</app-btn>
    <!-- <button class="clean button" type="button" (click)="closeClick()">Create your token later</button> -->
  </form>
</div>
