import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormBuilder, FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {ErrorStateMatcher} from "@angular/material/core";
import {
  IWalletListUnspentQueryResult,
  SocialNetworkClient,
  TransactionFee,
  TransactionFeeQueryResult,
  TransactionFeeType, TransactionViewModel,
  WalletBalanceQueryResult,
  WalletCoinType
} from "../../components/SocialNetworkClient";
import {CilTransactionHelper} from "../../models/Cil/CilTransaction";
import {BalanceErrorComponent} from "../balance-error/balance-error.component";
import {filter, map, takeUntil} from "rxjs/operators";
import {DestroyComponent} from "../../components/access.to.wallet/destroy-component";
import {CilSmartContractTransactionModel} from "../../models/transaction.model";
import {environment} from "../../../../environments/environment";

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-send-transaction',
  templateUrl: './send-transaction.component.html',
  styleUrls: ['./send-transaction.component.sass']
})
export class SendTransactionComponent extends DestroyComponent implements OnInit  {

  loading = new BehaviorSubject<boolean>(true);
  error = new Subject<string | null>;
  // @ts-ignore
  fees = new BehaviorSubject<TransactionFeeQueryResult>(null);
  balance = new BehaviorSubject<{value: number | undefined} | null>(null);
  currentFee$: Observable<TransactionFee> | undefined;
  requiredAmount: number;
  transactionFee: number = 0;
  //transactionModel = new BehaviorSubject<TransactionModel | null>(null);

  cilTransactionViewModel = new BehaviorSubject<TransactionViewModel | null>(null);
  cilSmartContractTransactionModel = new BehaviorSubject<CilSmartContractTransactionModel | null>(null);

  isGenerateTransaction = new BehaviorSubject(true);
  amountMatcher = new MyErrorStateMatcher();
  hashAddress = ''
  id: string;
  sendForm = this.formBuilder.group({
  }, {});

  contractAddress = environment.cilTokenContractAddress;

  constructor(protected socialNetworkClient: SocialNetworkClient,
              protected formBuilder: FormBuilder,
              protected dialog: MatDialog,
              public dialogRef: MatDialogRef<SendTransactionComponent>,
              private snackBar: MatSnackBar,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    super();
    this.id = data.id;
    this.hashAddress = data.hashAddress;
    this.requiredAmount = environment.cilTokenRequiredAmount +
      environment.cilTokenRequiredProof;

    this.currentFee$ = this.fees
      .pipe(takeUntil(this.destroyed$))
      .pipe(filter(fees => !!fees))
      // @ts-ignore
      .pipe(map(fees => fees.transactionFee.filter(x => x.type === this.sendForm.value.transactionFee)[0] || fees.transactionFee[0]));

    of(new TransactionFeeQueryResult({
      limit: 0,
      transactionFee: [new TransactionFee({
        coinValue: 0,
        type: TransactionFeeType.Average,
        value: '0'
      })]
    }))
      .subscribe((fees: TransactionFeeQueryResult) => {
        this.fees.next(fees);
        this.loading.next(false);
      }, (err) => {
        this.error.next(err);
        this.loading.next(false);
      });

    this.loading.next(false);
  }

  ngOnInit() {
    this.loading.next(true);
    this.socialNetworkClient.getBalance(this.hashAddress, this.id)
      .subscribe((data: WalletBalanceQueryResult) => {
        this.balance.next({ value: data.balance });
        this.cilAmountChange();
      },() => {
        this.loading.next(false);
      });
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    try {
      this.snackBar.dismiss();
    } catch {}
  }

  closeClick(isPrev: boolean = false): void {
    if (isPrev) {
      this.dialogRef.close({isPrev: true});
    } else {
      this.dialogRef.close();
    }
  }

  generateTransaction(): void {
    if (this.sendForm.invalid) {
      return;
    }
    if (this.transactionFee === 0) {
      return;
    }

    this.loading.next(true);


    // FILL SMART-CONTRACT MODEL
    this.cilSmartContractTransactionModel.next({
      amount: environment.cilTokenRequiredAmount +
        environment.cilTokenRequiredProof,
      walletName: 'CIL',
      coinType: WalletCoinType.Cil,
      addressFrom: this.hashAddress,
      fee: this.transactionFee, //number
      contractAddress: environment.cilTokenContractAddress, //string
      smartContractId: environment.cilTokenSmartContractId, //number
      consiliumId: environment.cilTokenConsiliumId, //number
      method: "createToken", //string
      params: [
        {name: "strSymbol", value: this.data.tiket},
        {name: "nTotalSupply", value: this.data.emission},
        {name: "strIssuerName", value: "4Tokens"},
        {name: "strGoals", value: this.data.description},
        {name: "decimals", value: Number(this.data.decimal)},
      ] //MethodParam[]
    });
    this.loading.next(false);
    this.isGenerateTransaction.next(false);
  }

  cilAmountChange() {
    this.socialNetworkClient.getWalletListUnspent(this.hashAddress, this.id)
      .subscribe(unspents => {
        try {
          //CALCULATE INPUTS
          // let {arrCoins, gathered} = CilTransactionHelper.gatherInputsForContractCall(unspents,
          //   environment.cilTokenRequiredAmount +
          //   environment.cilTokenRequiredProof +
          //   environment.cilTokenTransferAmount +
          //   environment.cilTokenCreateTransferFees);
          // //CALCULATE CONTRACT DATA LENGTH
          // let contractDataLength = 115; //скобки, запятые
          // contractDataLength += 11; //createToken
          // contractDataLength += this.data.tiket.length;
          // contractDataLength += String(this.data.emission).length;
          // contractDataLength += this.data.description.length;
          // contractDataLength += String(this.data.decimal).length;
          // //CALCULATE FEE
          // let fee  = CilTransactionHelper.calculateTxFee(contractDataLength, arrCoins.length, 1);
          // const utxoCount = CilTransactionHelper.UTXOCount(unspents);
          // while ((gathered < environment.cilTokenRequiredAmount + environment.cilTokenRequiredProof + fee) && (arrCoins.length !== utxoCount))
          // {
          //   const val = CilTransactionHelper.gatherInputsForContractCall(unspents, environment.cilTokenRequiredAmount + environment.cilTokenRequiredProof + fee);
          //   arrCoins = val.arrCoins;
          //   gathered = val.gathered;
          //
          //   fee  = CilTransactionHelper.calculateTxFee(contractDataLength, arrCoins.length, 1);
          //
          //
          // }

          const fee = CilTransactionHelper.getFee(environment.cilTokenRequiredAmount +
            environment.cilTokenRequiredProof +
            environment.cilTokenTransferAmount +
            environment.cilTokenCreateTransferFees, unspents).fee;

          this.transactionFee = fee + environment.cilTokenTransferAmount + environment.cilTokenCreateTransferFees;
          // @ts-ignore
          this.fees.value.transactionFee[0].coinValue = fee;
          // @ts-ignore
          this.fees.value.transactionFee[0].value = '' + fee;
          this.error.next(null);
          this.checkBalance();
          this.loading.next(false);
        } catch (error) {
          this.showBalanceError((error as Error).message);
          this.error.next((error as Error).message);
          this.loading.next(false);
        }
      });
  }

  checkBalance(): number | undefined {
    const balance = this.balance.getValue();
    // @ts-ignore
    if ((this.requiredAmount + this.transactionFee) > balance.value) {
      this.showBalanceError('Sorry, you don\'t have enough UBX on the balance to process the transaction');
      this.error.next('Sorry, you don\'t have enough UBX on the balance to process the transaction');
    } else {
      this.error.next('');
    }
    return balance?.value;
  }

  protected showBalanceError(errorMsg: string): void {
    this.snackBar.openFromComponent(BalanceErrorComponent, {
      verticalPosition: 'bottom',
      panelClass: 'error',
      // duration: 5000,
      data: {errorMsg}
    });
  }
}
