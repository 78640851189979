import {NgModule} from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HeroSectionComponent } from "./components/hero-section/hero-section.component";
import { LandingComponent } from "./landing.component";
import { ContainerComponent } from "../shared/components/container/container.component";
import { FillingBtnComponent } from "./components/filling-btn/filling-btn.component";
import { GradientBgComponent } from './components/gradient-bg/gradient-bg.component';
import { RectanglesTopComponent } from './components/hero-section/components/rectangles-top/rectangles-top.component';
import { RectanglesBottomComponent } from './components/hero-section/components/rectangles-bottom/rectangles-bottom.component';
import { HeaderComponent } from './components/header/header.component';
import {CommonModule, NgOptimizedImage} from "@angular/common";
import { DagSectionComponent } from './components/dag-section/dag-section.component';
import { EcosystemSectionComponent } from './components/ecosystem-section/ecosystem-section.component';
import { HowItWorksSectionComponent } from './components/how-it-works-section/how-it-works-section.component';
import { BtnComponent } from "../shared/components/btn/btn.component";
import { UseCasesSectionComponent } from './components/use-cases-section/use-cases-section.component';
import { CardComponent } from './components/card/card.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomePageComponent } from "../home-page/home-page.component";
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AccessToWalletComponent } from "../home-page/components/access.to.wallet/access-to-wallet/access-to-wallet.component";
import { AccessWithKeyComponent } from "../home-page/components/access.to.wallet/access-to-wallet/access-with-key/access-with-key.component";
import { AccessWithKeystoreComponent } from "../home-page/components/access.to.wallet/access-to-wallet/access-with-keystore/access-with-keystore.component";
import { TransactionGenerationErrorComponent } from "../home-page/components/access.to.wallet/transaction-generation-error/transaction-generation-error.component";
import { TransactionGenerationSuccessComponent } from "../home-page/components/access.to.wallet/transaction-generation-success/transaction-generation-success.component";
import { BalanceErrorComponent } from "../home-page/dialogs/balance-error/balance-error.component";
import { ConfirmWalletComponent } from "../home-page/dialogs/confirm-wallet/confirm-wallet.component";
import { NewTokenComponent } from "../home-page/dialogs/new-token/new-token.component";
import { NewWalletComponent } from "../home-page/dialogs/new-wallet/new-wallet.component";
import { SendConfirmComponent } from "../home-page/dialogs/send-confirm/send-confirm.component";
import { SendTransactionComponent } from "../home-page/dialogs/send-transaction/send-transaction.component";
import { TransactionCompleteComponent } from "../home-page/dialogs/transaction-complete/transaction-complete.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MaterialAngularModule } from "src/material.module";
import { BtnOutlineComponent } from "../shared/components/btn-outline/btn-outline.component";
import { DialogCardComponent } from "../shared/components/card/card.component";
import { FaqComponent } from './pages/faq/faq.component';
import { HomeComponent } from './pages/home/home.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { AccordionItemComponent } from './components/accordion/accordion-item/accordion-item.component';
import { UseCaseComponent } from './pages/use-case/use-case.component';
import { UseCasesService } from "./services/useCases.service";
import {ProtectedImagePipe, TokensComponent} from "./pages/tokens/tokens.component";
import {MessageBoxComponent} from "./components/message-box/message-box.component";
import {TokenInfoBoxComponent} from "./components/token-info-box/token-info-box.component";
import {RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings} from "ng-recaptcha";
import {environment} from "../../environments/environment";
import {LoaderComponent} from "./components/loader/loader.component";
import { SupportModule } from "../shared/components/support/support.module";

const routes: Routes = [
    {
        path: '',
        component: LandingComponent,
        children: [
            {
                path: '',
                component: HomeComponent
            },
            {
                path: 'faq',
                component: FaqComponent
            },
            {
                path: 'tokens',
                component: TokensComponent
            },
            {
                path: ':id',
                component: UseCaseComponent
            }
        ]
    }

];

@NgModule({
    declarations: [
        LandingComponent,
        HeroSectionComponent,
        FillingBtnComponent,
        GradientBgComponent,
        RectanglesTopComponent,
        RectanglesBottomComponent,
        HeaderComponent,
        DagSectionComponent,
        EcosystemSectionComponent,
        HowItWorksSectionComponent,
        UseCasesSectionComponent,
        CardComponent,
        FooterComponent,
        HomePageComponent,
        NewWalletComponent,
        AccessToWalletComponent,
        AccessWithKeyComponent,
        AccessWithKeystoreComponent,
        ConfirmWalletComponent,
        NewTokenComponent,
        SendTransactionComponent,
        BalanceErrorComponent,
        SendConfirmComponent,
        TransactionGenerationErrorComponent,
        TransactionGenerationSuccessComponent,
        TransactionCompleteComponent,
        FaqComponent,
        HomeComponent,
        AccordionComponent,
        AccordionItemComponent,
        UseCaseComponent,
        TokensComponent,
        MessageBoxComponent,
        TokenInfoBoxComponent,
        ProtectedImagePipe,
        LoaderComponent
    ],
    exports: [
        RouterModule,
        HomePageComponent
    ],
    providers: [
      {
        provide: RECAPTCHA_SETTINGS,
        useValue: {
          siteKey: environment.reCaptchaKey,
        } as RecaptchaSettings,
      },
      UseCasesService
    ],
    imports: [
        RouterModule.forChild(routes),
        ContainerComponent,
        BtnComponent,
        BtnOutlineComponent,
        DialogCardComponent,
        CommonModule,
        FormsModule,
        MatNativeDateModule,
        MaterialAngularModule,
        ReactiveFormsModule,
        BtnComponent,
        AngularSvgIconModule,
        NgOptimizedImage,
        RecaptchaModule,
        RecaptchaFormsModule,
        SupportModule,
    ]
})
export class LandingModule {

}
