<div class="loader-wrap" *ngIf="loading | async">
  <div class="loader-el"></div>
</div>
<div class="dialog__content">
  <div class="flex mb-5">
    <button class="dialog__close" (click)="closeClick()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <ng-container *ngIf="isGenerateTransaction | async; else confirmTransaction">
    <form class="dialog__wrap" [formGroup]="sendForm" (ngSubmit)="generateTransaction()">
      <h1 class="dialog__step">
        <button class="dialog__prevStep" type="button" (click)="closeClick(true)">
          <img src="./assets/svg/icons/arrow.svg" alt="<">
        </button>
        <p>Step 6</p>
        <button class="dialog__nextStep" type="submit" [ngClass]="{'disabled' : (this.sendForm.invalid || ((currentFee$ | async)!.value === '0'))}" [disabled]="(this.sendForm.invalid || ((currentFee$ | async)!.value === '0'))">
          <img src="./assets/svg/icons/arrow.svg" alt=">">
        </button>
      </h1>

      <h2 class="dialog__title mb-8">Send Transaction</h2>

      <div class="grid grid-cols-2 gap-2 mb-6">
        <app-dialog-card>
          <p class="text-grey">Amount</p>
          <p class="text-white">{{requiredAmount | number:'1.0-5'}} UBX</p>
        </app-dialog-card>
        <app-dialog-card>
          <p class="text-grey">Fee Price</p>
          <p class="text-white">{{(transactionFee > 0) ? (transactionFee | number:'1.0-9') + ' UBX' : '-'}}</p>
        </app-dialog-card>
        <app-dialog-card class="col-span-2">
          <p class="text-grey">Total</p>
          <p class="text-white">{{(transactionFee > 0) ? ((requiredAmount + transactionFee) | number:'1.0-9') + ' UBX' : '-'}}</p>
        </app-dialog-card>
      </div>

      <div class="mb-8">
        <app-dialog-card class="mb-2">
          <p class="text-grey mb-[10px]">Emission Account</p>
          <p class="text-white break-all">{{hashAddress}}</p>
        </app-dialog-card>

        <ng-container *ngIf="balance | async as balance">
          <app-dialog-card>
            <p class="text-grey">Balance</p>
            <p class="text-white">{{balance.value | number:'1.0-5'}} UBX</p>
          </app-dialog-card>
        </ng-container>
      </div>

      <app-btn class="mt-auto md:w-full" type="submit" [disabled]="(this.sendForm.invalid || ((currentFee$ | async)!.value === '0'))">Continue</app-btn>
    </form>
  </ng-container>
</div>

<ng-template #loadingTemplate>
  <div class="loader-wrap">
    <div class="loader-el"></div>
  </div>
</ng-template>

<ng-template #confirmTransaction>
  <app-send-confirm
    [tiket]="data.tiket"
    [name]="data.name"
    [description]="data.description"
    [decimals]="data.decimals"
    [contractAddress]="contractAddress"
    [emissionAmount]="data.emission"
    [emissionAddress]="hashAddress.substr(2)"
    [logo]="data.logo"
    [facebook]="data.facebook"
    [twitter]="data.twitter"
    [telegram]=""
    [tags]="data.tags"
    [units]="'UBX'"
    [cilSmartContractTransactionModel]="cilSmartContractTransactionModel"
    [accountBalance] = "balance"
    [dialogRef] = "dialogRef"
    [id] = "id"
    (isGenerateTransaction)="isGenerateTransaction.next($event)"
    (loading)="loading.next($event)">
  </app-send-confirm>
</ng-template>
