<div data-depth="0.3" class="w-full h-full">
    <img 
        class='right-[-50px] top-[382px] w-[113px] opacity-50 xl:hidden absolute' 
        src='./assets/images/landing/hero/rect4.svg' alt=''/>
</div>
<div data-depth="0.3" class="w-full h-full">
    <img 
        class='w-[231px] top-0 right-[-134px] xl:w-auto xl:top-[-150px] xl:right-[-220px] absolute' 
        src='./assets/images/landing/hero/rect4.svg' alt=''/>
</div>
<div data-depth="0.4" class="w-full h-full">
    <img 
        class='w-[181px] left-[-134px] top-[320px] xl:w-auto xl:top-[570px] xl:left-[-165px] absolute' 
        src='./assets/images/landing/hero/rect5.svg' alt=''/>
</div>
