<div class="dialog__wrap" *ngIf="cilSmartContractTransactionModel | async as transaction">
  <h1 class="dialog__step">
    <button class="dialog__prevStep" type="button" (click)="isGenerateTransaction.emit(true)">
      <img src="./assets/svg/icons/arrow.svg" alt="<">
    </button>
    <p>Step 7</p>
    <button class="dialog__nextStep" type="button" (click)="openAccessWalletDialog()">
      <img src="./assets/svg/icons/arrow.svg" alt=">">
    </button>
  </h1>
  <h2 class="dialog__title mb-8">Confirm Transaction</h2>

  <div class="grid grid-cols-2 gap-2 mb-6">
    <app-dialog-card>
      <p class="text-grey">Amount</p>
      <p class="text-white">{{transaction.amount | number:'1.0-5'}} {{units}}</p>
    </app-dialog-card>
    <app-dialog-card>
      <p class="text-grey">Fee Price</p>
      <p class="text-white">{{transaction.fee | number:'1.0-9'}} UBX</p>
    </app-dialog-card>
    <app-dialog-card class="col-span-2">
      <p class="text-grey">Total</p>
      <p class="text-white">{{transaction.amount+transaction.fee | number:'1.0-9'}} UBX</p>
    </app-dialog-card>
  </div>

  <div class="mb-8">
    <app-dialog-card class="mb-2">
      <p class="text-grey mb-[10px]">Emission Account</p>
      <p class="text-white break-all">{{transaction.addressFrom}}</p>
    </app-dialog-card>

    <app-dialog-card *ngIf="accountBalance | async as balance">
      <p class="text-grey">Balance</p>
      <p class="text-white">{{balance?.value | number:'1.0-8'}} {{units}}</p>
    </app-dialog-card>
  </div>

  <app-btn class="mt-auto md:w-full" (click)="openAccessWalletDialog()">Continue</app-btn>
</div>
