<div
    id="contacts"
    class='
        bg-purple
        pt-[90px]
        pb-[65px]
        xl:pt-[152px]
        xl:pb-[115px]
        relative'>
    <app-container class='xl:w-[1378px] grid gap-16 grid-cols-3 text-white'>
        <div class='col-span-3 xl:col-span-1 md:mx-auto xl:mx-0 max-w-[360px] mb-12 xl:mb-0'>
            <p class='text-md font-extrabold mb-5'>Get in touch with us</p>
            <p class='text-md leading-5 mb-9 xl:mb-14'>The best way to reach us is through our telegram community, but you may also contact us via email: <a href="mailto:welcome@4tokens.io" class='font-extrabold cursor-pointer'>welcome@4tokens.io</a></p>
            <!-- <ContactBtn/> -->
            <a href='https://t.me/UBIX_Network' target='_blank'>
                <button class="
                    w-full
                    max-w-full
                    rounded-lg
                    border-solid
                    border-1
                    bg-none
                    text-white
                    text-md
                    leading-6
                    font-manrope
                    font-extrabold
                    md:w-[254px]
                    relative
                    overflow-hidden
                    p-[14px]
                    hover:text-white
                    hover:border-white
                    active:text-white
                    active:border-white">
                    <div class="
                        absolute
                        flex
                        justify-center
                        items-center
                        rounded-[4px]
                        bg-white
                        left-2
                        top-[7px]
                        h-[52px]
                        w-[52px]">
                        <img src="./assets/images/landing/telegram.svg" alt="Telegram" class="h-min">
                    </div>
                    <span class="relative ml-[26px]">Contact Us</span>
                </button>
            </a>
        </div>

        <a href="./" class="col-span-3 xl:col-span-1 mx-auto order-first xl:order-none">
            <img class="w-[145px] xl:w-auto" src="./assets/images/landing/logo.svg" alt="4T">
        </a>

        <div class="col-span-3 xl:col-span-1 md:mx-auto xl:mx-0 flex justify-between xl:justify-end">
            <div class='mb-12 xl:mb-0 md:mr-16'>
                <p class='text-md font-extrabold mb-6'>Navigation</p>
                <app-home-page>
                  <a class='block mb-4'>Issue your Token</a>
                </app-home-page>
                <a [routerLink]="['./']" fragment="solution" class='block mb-4'>Solution</a>
                <a [routerLink]="['./']" fragment="ecosystem" class='block mb-4'>Ecosystem</a>
                <a [routerLink]="['./']" fragment="how-it-works" class='block mb-4'>How it works</a>
                <a [routerLink]="['./']" fragment="use-cases" class='block mb-4'>Use Cases</a>
            </div>

            <div class='mb-12 xl:mb-0'>
                <p class='text-md font-extrabold mb-6'>Documentation</p>
                <a class='block mb-4' routerLink="faq">FAQ</a>
                <a class='block mb-4' routerLink="tokens">Tokens list</a>
                <a class='block mb-4' href="https://helper.ubikiri.com/4tokens/usermanual.pdf" target="_blank">User Manual</a>
                <a class='block mb-4' href="https://helper.ubikiri.com/4tokens/useragreement4T.pdf" target="_blank">User Agreement</a>
            </div>
        </div>
    </app-container>
</div>

<div class='bg-black'>
    <app-container class='text-center py-5 xl:py-[18px]'>
        <p class='text-white text-sm xl:text-md'>UBIX.Network © {{currentYear}}</p>
    </app-container>
</div>
