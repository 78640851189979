import { Component, ElementRef, AfterViewInit, ViewChild } from "@angular/core";
import * as Parallax from 'parallax-js'

// declare const Parallax: any

@Component({
    selector: 'app-hero-section',
    templateUrl: './hero-section.component.html'
})
export class HeroSectionComponent implements AfterViewInit {
    @ViewChild('parallaxScene') sceneRef: ElementRef<HTMLDivElement> | null = null;
    ngAfterViewInit() {
        if (!this.sceneRef) return
        const parallaxInstance = new Parallax(this.sceneRef.nativeElement, {selector: '[data-depth]', pointerEvents: true})
    }
}