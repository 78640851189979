import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';


@Component({
  selector: 'app-transaction-generation-error',
  templateUrl: './transaction-generation-error.component.html'
})

export class TransactionGenerationErrorComponent {

  constructor(public snackBarRef: MatSnackBarRef<TransactionGenerationErrorComponent>,
              @Inject(MAT_SNACK_BAR_DATA) public data: any) {
  }

  closeClick(): void {
    this.snackBarRef.dismiss();
  }
}
