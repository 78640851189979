import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-filling-btn',
  templateUrl: './filling-btn.component.html',
  styleUrls: ['./filling-btn.component.scss']
})
export class FillingBtnComponent {
  @Input() click?: () => void = () => {}
  @Input() highlight?: boolean = false
}
