<div data-depth="0.1" class="w-full h-full">
    <img 
        class='hidden xl:block top-[178px] left-[112px] absolute opacity-40' 
        src='./assets/images/landing/hero/rect1.svg' alt=''/>
</div>
<div data-depth="0.2" class="w-full h-full">
    <img 
        class='hidden xl:block top-[647px] right-[-9px] absolute' 
        src='./assets/images/landing/hero/rect2.svg' alt=''/>
</div>
<div data-depth="0.15" class="w-full h-full">
    <img 
        class='hidden xl:block top-[815px] left-[672px] absolute' 
        src='./assets/images/landing/hero/rect3.svg' alt=''/>
</div>
