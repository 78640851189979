import {Environment} from "./interface";

export const environment: Environment = {
  production: false,
  apiKey: 'dd804f42cfd04827ba9ba229667ec5b4',
  adminKey: 'FGHJFRTe67wfdt67342gRFIi76',
  adminUrl: 'admin-4tt',
  reCaptchaKey: '6LcpJ20nAAAAAHMskB4dDKa_Owps_DeRL-sr7uyK',
  api: {
    // socialNetwork: 'https://localhost:6861'
    socialNetwork: 'https://fortoken-api.dev20021.ubikiri.com'
    // socialNetwork: 'https://api.4tokens.io'
  },
  cilTokenRequiredAmount: 150000,
  cilTokenContractAddress: '5560c0ee0c7ffdc4ccb033056fcc95c1974c3ed1',
  cilTokenRequiredProof: 350000,
  cilTokenProofAddress: '52fd856648e112e0816484f02464c5cab6ceefdc',
  cilTokenTransferAmount: 10000,
  cilTokenCreateTransferFees: 7500, //create - 5000, transfer - 2500
  cilTokenSmartContractId: 1,
  cilTokenConsiliumId: 1,
  explorerUri: 'https://test-explorer.ubikiri.com',
  // identityApi: "https://localhost:6861",
  identityApi: "https://fortoken-api.dev20021.ubikiri.com",
  // identityApi: "https://api.4tokens.io",
  projectCode: "fourTokens",
  supportChat: "https://chat-api.dev20021.ubix.network/api"
  // supportChat: "https://chat-api.ubix.network/api"
};
