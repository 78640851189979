import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupportComponent } from './support/support.component';
import { SupportDialogsComponent } from './support-dialogs/support-dialogs.component';
import { SupportChatComponent } from './support-chat/support-chat.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from "@angular/material/button";
import { FlexModule } from "@angular/flex-layout";
import {ReactiveFormsModule} from "@angular/forms";
import {MatTooltipModule} from "@angular/material/tooltip";


@NgModule({
  declarations: [
    SupportComponent,
    SupportDialogsComponent,
    SupportChatComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FlexModule,
    ReactiveFormsModule,
    MatTooltipModule
  ],
  exports: [
    SupportComponent
  ]
})
export class SupportModule { }
