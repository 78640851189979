import {Component, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';

@Component({template: ''})
// tslint:disable-next-line:component-class-suffix
export abstract class DestroyComponent implements OnDestroy {

  protected destroyed$ = new Subject();

  protected constructor() {}

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
