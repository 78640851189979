<div class="info-dialog">
<!--  <button class="close" (click)="closeClick()">-->
<!--    <img src="../../../../assets/images/icon_close.svg">-->
<!--  </button>-->
  <h2 class="h2-info">{{tickerName.toUpperCase()}}</h2>

  <mat-card class="tokenContent">
   <mat-card-content>
     <p><span class="text-name">Symbol: </span> <span class="text-caption">{{token.currency?.toUpperCase()}}</span></p>
     <p><span class="text-name">Ticker: </span> <span class="text-caption">{{token.ticker ? token.ticker.toUpperCase() : ''}}</span></p>
     <p><span class="text-name">Name: </span> <span class="text-caption">{{token.name}}</span></p>
     <p><span class="text-name">Description: </span> <span class="text-caption">{{token.description}}</span></p>
     <p><span class="text-name">Decimals: </span> <span class="text-caption">{{token.decimals}}</span></p>
     <p><span class="text-name">Emission address: </span> <span class="text-caption">{{token.emissionAddress}}</span></p>
     <p><span class="text-name">Emission amount: </span> <span class="text-caption">{{token.emissionAmount}}</span></p>
     <p><span class="text-name">Decimals: </span> <span class="text-caption">{{token.decimals}}</span></p>
     <p><span class="text-name">Contract address: </span> <span class="text-caption">{{token.contractAddress}}</span></p>
     <p><span class="text-name">Status: </span> <span class="text-caption">{{(token.status === undefined) ? 'Undefined' : AdminTokenStatus[token.status]}}</span></p>
     <p><span class="text-name">Facebook: </span> <span class="text-caption">{{token.community?.facebook}}</span></p>
     <p><span class="text-name">Twitter: </span> <span class="text-caption">{{token.community?.twitter}}</span></p>
     <p><span class="text-name">Telegram: </span> <span class="text-caption">{{token.community?.telegram}}</span></p>
     <p><span class="text-name">Logo: </span> <span class="text-caption">{{getLogoString(token.logo)}}</span></p>
     <p><span class="text-name">Tags: </span> <span class="text-caption">{{getTagsString(token.tags)}}</span></p>
     <p><span class="text-name">SourceCode: </span> <span class="text-caption">{{token.sourceCode}}</span></p>
    </mat-card-content>
  </mat-card>


  <button *ngIf="is_normal_token" class="dlg-close-button secLeftButton" (click)="clickSendCompliant()">
    Send compliant for normal
  </button>
  <button *ngIf="is_fake_token" class="dlg-close-button secLeftButton" (click)="clickSendDecision()">
    Show decision for fake
  </button>

  <button class="dlg-close-button" (click)="closeClick()">Cancel</button>
</div>
