import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';

@Component({
  selector: 'app-access-with-keystore',
  templateUrl: './access-with-keystore.component.html',
  styleUrls: ['./access-with-keystore.component.sass']
})

export class AccessWithKeystoreComponent implements OnChanges {

  @Input() error: string | undefined;
  @Input() isSendSmart: boolean | undefined;
  @Output() password = new EventEmitter<string>();

  showPwd = false;
  passwordForm = this.formBuilder.group({
    password: new FormControl('', [Validators.required, this.customValidator()])
  });

  constructor(private formBuilder: FormBuilder,
              private cdRef:ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.passwordForm.controls['password'].value) {
      this.passwordForm.controls['password'].markAsTouched();
      this.passwordForm.controls['password'].updateValueAndValidity();
    }
  }

  private customValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === null) {
        return null;
      }
      return (this.error) ? {'check': 'error'} : null;
    };
  }

  onSubmit(isPrev: boolean = false) {
    if (isPrev){
      this.password.emit('');
    } else {
      if (this.passwordForm.invalid) {
        return;
      }
      // @ts-ignore
      this.password.emit(this.passwordForm.value.password);
    }
  }

  changePassword(value: string | null = null) {
    if (value !== null) { this.passwordForm.setValue({password: value}); }
    this.error = undefined;
    this.cdRef.detectChanges();
    this.passwordForm.controls['password'].markAsTouched();
    this.passwordForm.controls['password'].updateValueAndValidity();
  }
}
